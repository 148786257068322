import { useState } from 'react';
import './WallTri.css'
import Box from '@mui/material/Box';
function WallTri() {
const [isActive, setIsActive]=useState(false)
  const dosome = (e) => {
    console.log(e.currentTarget.className);
    setIsActive(true)
  }
  return (
    <Box sx={{ marginTop:"60%",  marginLeft:'20%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems:"center"}}>
    <div id="tridivWall" style={{ background:"rgba(0,0,0,0)" }}>
      <div className="sceneWall" style={{ transform: "rotateX(-20deg) rotateY(-30deg)",}}>
        <div className="shapeWall cuboid-1Wall cub-1Wall" onClick={(e) => { dosome(e) }}>
          <div className="faceWall ftWallWall">
            <div className="photon-shaderWall" style={{  background: isActive ? '#acdccf' : '', color: isActive ? 'white' : '',}}></div>
          </div>
          <div className="faceWall bkWall">
            <div className="photon-shaderWall" style={{ background: isActive ? '#acdccf' : '', color: isActive ? 'white' : '', }}></div>
          </div>
          <div className="faceWall rtWall">
            <div className="photon-shaderWall" style={{ background: isActive ? '#acdccf' : '', color: isActive ? 'white' : '',}}></div>
          </div>
          <div className="faceWall ltWall">
            <div className="photon-shaderWall" style={{ background: isActive ? '#acdccf' : '', color: isActive ? 'white' : '', }}></div>
          </div>
          <div className="faceWall bmWall">
            <div className="photon-shaderWall" style={{ background: isActive ? '#acdccf' : '', color: isActive ? 'white' : '', }}></div>
          </div>
          <div className="faceWall tpWall">
            <div className="photon-shaderWall" style={{ background: isActive ? '#acdccf' : '', color: isActive ? 'white' : '', }}></div>
          </div>
        </div>
        <div className="shapeWall cuboid-2Wall cub-2Wall" onClick={(e) => { dosome(e) }}>
          <div className="faceWall ftWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}></div>
          </div>
          <div className="faceWall bkWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}></div>
          </div>
          <div className="faceWall rtWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}></div>
          </div>
          <div className="faceWall ltWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}></div>
          </div>
          <div className="faceWall bmWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}></div>
          </div>
          <div className="faceWall tpWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}></div>
          </div>
        </div>
        <div className="shapeWall cuboid-3Wall cub-3Wall" onClick={(e) => { dosome(e) }}>
          <div className="faceWall ftWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}></div>
          </div>
          <div className="faceWall bkWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}></div>
          </div>
          <div className="faceWall rtWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}></div>
          </div>
          <div className="faceWall ltWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}></div>
          </div>
          <div className="faceWall bmWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}></div>
          </div>
          <div className="faceWall tpWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}></div>
          </div>
        </div>
        <div className="shapeWall cuboid-4Wall cub-4Wall">
          <div className="faceWall ftWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}></div>
          </div>
          <div className="faceWall bkWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}></div>
          </div>
          <div className="faceWall rtWallWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}></div>
          </div>
          <div className="faceWall ltWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}></div>
          </div>
          <div className="faceWall bmWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}></div>
          </div>
          <div className="faceWall tpWall">
            <div className="photon-shaderWall" style={{ backgroundColor: "rgba(255, 255, 255, 0)" }}></div>
          </div>
        </div>
      </div>

    </div>
    </Box>

  )
}

export default WallTri;