import React, { useState } from 'react';
import { Box } from '@mui/material';
import './HybridWall.css';

function HybridWall({ dosome, isActiveWin1, isActiveWin2, isActiveWin3, isActiveWin4, isActiveWin5, isActiveWin6, isActiveWin7, isActiveWin8, isActiveWin9, isActiveWin10 }) {
    const [hoveredCuboid, setHoveredCuboid] = useState(null);

    const handleMouseEnter = (cuboidId) => {
        setHoveredCuboid(cuboidId);
    };

    const handleMouseLeave = () => setHoveredCuboid(null);

    const faceStyle = (isActive, cuboidId) => {
        const isHovered = hoveredCuboid === cuboidId || 
                          (hoveredCuboid >= 5 && hoveredCuboid <= 9 && cuboidId >= 5 && cuboidId <= 9);

        return {
            background: isHovered ? 'rgba(56, 191, 191, 0.8)' : isActive ? '#acdccf' : '',
            color: isActive ? 'white' : '',
        };
    };

    return (
        <Box sx={{ display: 'flex', marginTop: "80%", flexDirection: 'row', justifyContent: 'center', alignItems: "center", background: "black" }}>
            <div id="tridivHyb">
                <div className="sceneHyb" style={{
                    WebkitTransform: 'rotateX(8deg) rotateY(62deg)',
                    MozTransform: 'rotateX(8deg) rotateY(62deg)',
                    msTransform: 'rotateX(8deg) rotateY(62deg)',
                    transform: 'rotateX(4deg) rotateY(45deg)',
                }}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((cuboidId) => {
                        const isActive = eval(`isActiveWin${cuboidId}`); // Gets the respective isActive state for each cuboid
                        return (
                            <div key={cuboidId} 
                                className={`shapeHyb cuboid-${cuboidId} cub-${cuboidId}Hyb`}
                                onClick={dosome}
                                // onMouseEnter={() => handleMouseEnter(cuboidId)}
                                // onMouseLeave={handleMouseLeave}
                                >
                                {['ft', 'bk', 'rt', 'lt', 'bm', 'tp'].map((face) => (
                                    <div
                                        key={face}
                                        className={`faceHyb ${face}`}
                                        style={faceStyle(isActive, cuboidId)}
                                    ></div>
                                ))}
                            </div>
                        );
                    })}
                </div>
            </div>
        </Box>
    );
}

export default HybridWall;
