import './SmartWall.css';

import Box from '@mui/material/Box';


function SmartWall({showMeTheClass, isActive,isActive3,isActive4,isActive5,isActive6}) {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems:"center"}}>
            <div id="tridiv" style={{background:"rgba(0,0,0,0)"}}>
                
                <div className="scene" style={{ transform: "rotateY(55deg)"}} >
                    <div className="shape cuboid-1 cub-1" onClick={showMeTheClass}>
                        <div className="face ft" style={{ background: isActive ? '#acdccf' : '', color: isActive ? 'white' : '', }}></div>
                        <div className="face bk" style={{ background: isActive ? '#acdccf' : '', color: isActive ? 'white' : '', }}></div>
                        <div className="face rt" style={{ background: isActive ? '#acdccf' : '', color: isActive ? 'white' : '', }}></div>
                        <div className="face lt" style={{ background: isActive ? '#acdccf' : '', color: isActive ? 'white' : '', }}></div>
                        <div className="face bm" style={{ background: isActive ? '#acdccf' : '', color: isActive ? 'white' : '', }}></div>
                        <div className="face tp" style={{ background: isActive ? '#acdccf' : '', color: isActive ? 'white' : '', }}></div>
                    </div>
                    <div className="shape cuboid-2 cub-2">
                        <div className="face ft" ></div>
                        <div className="face bk" ></div>
                        <div className="face rt" ></div>
                        <div className="face lt" ></div>
                        <div className="face bm" ></div>
                        <div className="face tp" ></div>
                    </div>
                    <div className="shape cuboid-3 cub-3" >
                        <div className="face ft" style={{ background: isActive3 ? '#acdccf' : '', color: isActive3 ? 'white' : '', }}></div>
                        <div className="face bk" style={{ background: isActive3 ? '#acdccf' : '', color: isActive3 ? 'white' : '', }}></div>
                        <div className="face rt" style={{ background: isActive3 ? '#acdccf' : '', color: isActive3 ? 'white' : '', }}></div>
                        <div className="face lt" style={{ background: isActive3 ? '#acdccf' : '', color: isActive3 ? 'white' : '', }}></div>
                        <div className="face bm" style={{ background: isActive3 ? '#acdccf' : '', color: isActive3 ? 'white' : '', }}></div>
                        <div className="face tp" style={{ background: isActive3 ? '#acdccf' : '', color: isActive3 ? 'white' : '', }}></div>
                    </div>
                    <div className="shape cuboid-4 cub-4" onClick={showMeTheClass}>
                        <div className="face ft" style={{ background: isActive4 ? '#acdccf' : '', color: isActive4 ? 'white' : '', }}></div>
                        <div className="face bk" style={{ background: isActive4 ? '#acdccf' : '', color: isActive4 ? 'white' : '', }}></div>
                        <div className="face rt" style={{ background: isActive4 ? '#acdccf' : '', color: isActive4 ? 'white' : '', }}></div>
                        <div className="face lt" style={{ background: isActive4 ? '#acdccf' : '', color: isActive4 ? 'white' : '', }}></div>
                        <div className="face bm" style={{ background: isActive4 ? '#acdccf' : '', color: isActive4 ? 'white' : '', }}></div>
                        <div className="face tp" style={{ background: isActive4 ? '#acdccf' : '', color: isActive4 ? 'white' : '', }}></div>
                    </div>
                    <div className="shape cuboid-5 cub-5" onClick={showMeTheClass}>
                        <div className="face ft" style={{ background: isActive5 ? '#acdccf' : '', color: isActive5 ? 'white' : '', }}></div>
                        <div className="face bk" style={{ background: isActive5 ? '#acdccf' : '', color: isActive5 ? 'white' : '', }}></div>
                        <div className="face rt" style={{ background: isActive5 ? '#acdccf' : '', color: isActive5 ? 'white' : '', }}></div>
                        <div className="face lt" style={{ background: isActive5 ? '#acdccf' : '', color: isActive5 ? 'white' : '', }}></div>
                        <div className="face bm" style={{ background: isActive5 ? '#acdccf' : '', color: isActive5 ? 'white' : '', }}></div>
                        <div className="face tp" style={{ background: isActive5 ? '#acdccf' : '', color: isActive5 ? 'white' : '', }}></div>
                    </div>
                    <div className="shape cuboid-6 cub-6" onClick={showMeTheClass}>
                        <div className="face ft" style={{ background: isActive6 ? '#acdccf' : '', color: isActive6 ? 'white' : '', }}></div>
                        <div className="face bk" style={{ background: isActive6 ? '#acdccf' : '', color: isActive6 ? 'white' : '', }}></div>
                        <div className="face rt" style={{ background: isActive6 ? '#acdccf' : '', color: isActive6 ? 'white' : '', }}></div>
                        <div className="face lt" style={{ background: isActive6 ? '#acdccf' : '', color: isActive6 ? 'white' : '', }}></div>
                        <div className="face bm" style={{ background: isActive6 ? '#acdccf' : '', color: isActive6 ? 'white' : '', }}></div>
                        <div className="face tp" style={{ background: isActive6 ? '#acdccf' : '', color: isActive6 ? 'white' : '', }}></div>
                    </div>
                </div>
            </div>
            
        </Box>
    );
}

export default SmartWall;