import Home from './Home';
import './scss/App.scss';
import LoginPage from './LoginPage';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./comp/Keycloak";
import PrivateRoute from './helpers/PrivateRoute';
import Modest from './Modest';
import WindowSec from './trials/WindowSec';
import WallTri from './trials/WallTri';
import SmartWall from './trials/SmartWall';
import Cube from './trials/Cube';
import HybridWall from './trials/HybridWall';
import PostRenovation from './PostRenovation';



function App() {
  return (
    <div className="App">
      <ReactKeycloakProvider authClient={keycloak}>
        <BrowserRouter>
          <Routes>
          {/* <Route path="/home" element={<Home />} /> */}
            <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
            {/* <Route path="/modest" element={<Modest />} /> */}
            <Route path="/modest" element={<PrivateRoute><Modest /></PrivateRoute>} />
            <Route path="/" element={<LoginPage />} />
            <Route path="/postreno" element={<PostRenovation />} />
          </Routes>
        </BrowserRouter>
      </ReactKeycloakProvider>
    </div>
  );
}

export default App;
