import React, { useState, useEffect } from 'react';
import './Cube.css';

const Cube = () => {
  const [rotation, setRotation] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [lastMousePosition, setLastMousePosition] = useState({ x: 0, y: 0 });

  const startDragging = (e) => {
    setIsDragging(true);
    setLastMousePosition({ x: e.clientX, y: e.clientY });
  };

  const stopDragging = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const deltaX = e.clientX - lastMousePosition.x;
      const deltaY = e.clientY - lastMousePosition.y;

      requestAnimationFrame(() => {
        setRotation((prevRotation) => ({
          x: prevRotation.x + deltaY * 0.5, // Adjust for smoother control
          y: prevRotation.y + deltaX * 0.5,
        }));
      });

      setLastMousePosition({ x: e.clientX, y: e.clientY });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      // Optionally adjust responsiveness on resize
      // e.g., change perspective or size depending on the viewport size
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      className="cube-containerC"
      onMouseDown={startDragging}
      onMouseMove={handleMouseMove}
      onMouseUp={stopDragging}
      onMouseLeave={stopDragging}
    >
      <div
        className="cubeC"
        style={{
          transform: `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`,
        }}
      >
        <div className="faceC frontC">Front</div>
        <div className="faceC backC">Back</div>
        <div className="faceC leftC">Left</div>
        <div className="faceC rightC">Right</div>
        <div className="faceC topC">Top</div>
        <div className="faceC bottomC">Bottom</div>
      </div>
    </div>
  );
};

export default Cube;
