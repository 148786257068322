import './ConExWall.css';

import Box from '@mui/material/Box';


function ConExWall({showMeTheClass, isActiveCon,isActiveCon2,isActiveCon3,isActiveCon4,isActiveCon5,isActiveCon6}) {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems:"center"}}>
            <div id="tridivCon" style={{background:"rgba(0,0,0,0)"}}>
                
                <div className="sceneCon" style={{ transform: "rotateY(55deg)"}} >
                    <div className="shapeCon cuboid-1 cub-1Con" >
                        <div className="faceCon ft" style={{ background: isActiveCon ? '#acdccf' : '', color: isActiveCon ? 'white' : '', }}></div>
                        <div className="faceCon bk" style={{ background: isActiveCon ? '#acdccf' : '', color: isActiveCon ? 'white' : '', }}></div>
                        <div className="faceCon rt" style={{ background: isActiveCon ? '#acdccf' : '', color: isActiveCon ? 'white' : '', }}></div>
                        <div className="faceCon lt" style={{ background: isActiveCon ? '#acdccf' : '', color: isActiveCon ? 'white' : '', }}></div>
                        <div className="faceCon bm" style={{ background: isActiveCon ? '#acdccf' : '', color: isActiveCon ? 'white' : '', }}></div>
                        <div className="faceCon tp" style={{ background: isActiveCon ? '#acdccf' : '', color: isActiveCon ? 'white' : '', }}></div>
                    </div>
                    <div className="shapeCon cuboid-2 cub-2Con" onClick={showMeTheClass}>
                        <div className="faceCon ft" style={{ background: isActiveCon2 ? '#acdccf' : '', color: isActiveCon2 ? 'white' : '', }}></div>
                        <div className="faceCon bk" style={{ background: isActiveCon2 ? '#acdccf' : '', color: isActiveCon2 ? 'white' : '', }}></div>
                        <div className="faceCon rt" style={{ background: isActiveCon2 ? '#acdccf' : '', color: isActiveCon2 ? 'white' : '', }}></div>
                        <div className="faceCon lt" style={{ background: isActiveCon2 ? '#acdccf' : '', color: isActiveCon2 ? 'white' : '', }}></div>
                        <div className="faceCon bm" style={{ background: isActiveCon2 ? '#acdccf' : '', color: isActiveCon2 ? 'white' : '', }}></div>
                        <div className="faceCon tp" style={{ background: isActiveCon2 ? '#acdccf' : '', color: isActiveCon2 ? 'white' : '', }}></div>
                    </div>
                    <div className="shapeCon cuboid-3 cub-3Con" >
                        <div className="faceCon ft" style={{ background: isActiveCon3 ? '#acdccf' : '', color: isActiveCon3 ? 'white' : '', }}></div>
                        <div className="faceCon bk" style={{ background: isActiveCon3 ? '#acdccf' : '', color: isActiveCon3 ? 'white' : '', }}></div>
                        <div className="faceCon rt" style={{ background: isActiveCon3 ? '#acdccf' : '', color: isActiveCon3 ? 'white' : '', }}></div>
                        <div className="faceCon lt" style={{ background: isActiveCon3 ? '#acdccf' : '', color: isActiveCon3 ? 'white' : '', }}></div>
                        <div className="faceCon bm" style={{ background: isActiveCon3 ? '#acdccf' : '', color: isActiveCon3 ? 'white' : '', }}></div>
                        <div className="faceCon tp" style={{ background: isActiveCon3 ? '#acdccf' : '', color: isActiveCon3 ? 'white' : '', }}></div>
                    </div>
                    <div className="shapeCon cuboid-4 cub-4Con" >
                        <div className="faceCon ft" style={{ background: isActiveCon4 ? '#acdccf' : '', color: isActiveCon4 ? 'white' : '', }}></div>
                        <div className="faceCon bk" style={{ background: isActiveCon4 ? '#acdccf' : '', color: isActiveCon4 ? 'white' : '', }}></div>
                        <div className="faceCon rt" style={{ background: isActiveCon4 ? '#acdccf' : '', color: isActiveCon4 ? 'white' : '', }}></div>
                        <div className="faceCon lt" style={{ background: isActiveCon4 ? '#acdccf' : '', color: isActiveCon4 ? 'white' : '', }}></div>
                        <div className="faceCon bm" style={{ background: isActiveCon4 ? '#acdccf' : '', color: isActiveCon4 ? 'white' : '', }}></div>
                        <div className="faceCon tp" style={{ background: isActiveCon4 ? '#acdccf' : '', color: isActiveCon4 ? 'white' : '', }}></div>
                    </div>
                    <div className="shapeCon cuboid-5 cub-5Con" >
                        <div className="faceCon ft" style={{ background: isActiveCon5 ? '#acdccf' : '', color: isActiveCon5 ? 'white' : '', }}></div>
                        <div className="faceCon bk" style={{ background: isActiveCon5 ? '#acdccf' : '', color: isActiveCon5 ? 'white' : '', }}></div>
                        <div className="faceCon rt" style={{ background: isActiveCon5 ? '#acdccf' : '', color: isActiveCon5 ? 'white' : '', }}></div>
                        <div className="faceCon lt" style={{ background: isActiveCon5 ? '#acdccf' : '', color: isActiveCon5 ? 'white' : '', }}></div>
                        <div className="faceCon bm" style={{ background: isActiveCon5 ? '#acdccf' : '', color: isActiveCon5 ? 'white' : '', }}></div>
                        <div className="faceCon tp" style={{ background: isActiveCon5 ? '#acdccf' : '', color: isActiveCon5 ? 'white' : '', }}></div>
                    </div>
                    <div className="shapeCon cuboid-6 cub-6Con" onClick={showMeTheClass}>
                        <div className="faceCon ft" style={{ background: isActiveCon6 ? '#acdccf' : '', color: isActiveCon6 ? 'white' : '', }}></div>
                        <div className="faceCon bk" style={{ background: isActiveCon6 ? '#acdccf' : '', color: isActiveCon6 ? 'white' : '', }}></div>
                        <div className="faceCon rt" style={{ background: isActiveCon6 ? '#acdccf' : '', color: isActiveCon6 ? 'white' : '', }}></div>
                        <div className="faceCon lt" style={{ background: isActiveCon6 ? '#acdccf' : '', color: isActiveCon6 ? 'white' : '', }}></div>
                        <div className="faceCon bm" style={{ background: isActiveCon6 ? '#acdccf' : '', color: isActiveCon6 ? 'white' : '', }}></div>
                        <div className="faceCon tp" style={{ background: isActiveCon6 ? '#acdccf' : '', color: isActiveCon6 ? 'white' : '', }}></div>
                    </div>
                </div>
            </div>
            
        </Box>
    );
}

export default ConExWall;