import { useState } from 'react';
import './WindowSec.css';
import Box from '@mui/material/Box';

function WindowSec({ dosome, isActiveWin, isActiveWin2, isActiveWin3, isActiveWin4, isActiveWin5, isActiveWin6, isActiveWin7, isActiveWin8, isActiveWin9 }) {

  return (
    <Box sx={{ marginTop:"50%", marginLeft:"-80%", display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems:"center", }}>
  
    <div id="tridivWind" >
      <div className="sceneWind" style={{ transform: "rotateX(-75deg) rotateY(90deg)", }}>
        <div className="shapeWind cuboid-2Wind cub-2Wind" onClick={dosome}>
          <div className="faceWind ftWind" style={{ background: isActiveWin2 ? ' rgb(56, 191, 191)' : '', color: isActiveWin2 ? 'white' : '', }}></div>
          <div className="faceWind bkWind" style={{ background: isActiveWin2 ? ' rgb(56, 191, 191)' : '', color: isActiveWin2 ? 'white' : '', }}></div>
          <div className="faceWind rtWind" style={{ background: isActiveWin2 ? ' rgb(56, 191, 191)' : '', color: isActiveWin2 ? 'white' : '', }}></div>
          <div className="faceWind ltWind" style={{ background: isActiveWin2 ? ' rgb(56, 191, 191)' : '', color: isActiveWin2 ? 'white' : '', }}></div>
          <div className="faceWind bmWind" style={{ background: isActiveWin2 ? ' rgb(56, 191, 191)' : '', color: isActiveWin2 ? 'white' : '', }}></div>
          <div className="faceWind tpWind" style={{ background: isActiveWin2 ? ' rgb(56, 191, 191)' : '', color: isActiveWin2 ? 'white' : '', }}></div>
        </div>
        <div className="shapeWind cuboid-3Wind cub-3Wind" onClick={dosome}>
          <div className="faceWind ftWind" style={{ background: isActiveWin3 ? ' rgb(56, 191, 191)' : '', color: isActiveWin3 ? 'white' : '', }}></div>
          <div className="faceWind bkWind" style={{ background: isActiveWin3 ? ' rgb(56, 191, 191)' : '', color: isActiveWin3 ? 'white' : '', }}></div>
          <div className="faceWind rtWind" style={{ background: isActiveWin3 ? ' rgb(56, 191, 191)' : '', color: isActiveWin3 ? 'white' : '', }}></div>
          <div className="faceWind ltWind" style={{ background: isActiveWin3 ? ' rgb(56, 191, 191)' : '', color: isActiveWin3 ? 'white' : '', }}></div>
          <div className="faceWind bmWind" style={{ background: isActiveWin3 ? ' rgb(56, 191, 191)' : '', color: isActiveWin3 ? 'white' : '', }}></div>
          <div className="faceWind tpWind" style={{ background: isActiveWin3 ? ' rgb(56, 191, 191)' : '', color: isActiveWin3 ? 'white' : '', }}></div>
        </div>
        <div className="shapeWind cuboid-4Wind cub-4Wind" onClick={dosome}>
          <div className="faceWind ftWind" style={{ background: isActiveWin4 ? ' rgb(56, 191, 191)' : '', color: isActiveWin4 ? 'white' : '', }}></div>
          <div className="faceWind bkWind" style={{ background: isActiveWin4 ? ' rgb(56, 191, 191)' : '', color: isActiveWin4 ? 'white' : '', }}></div>
          <div className="faceWind rtWind" style={{ background: isActiveWin4 ? ' rgb(56, 191, 191)' : '', color: isActiveWin4 ? 'white' : '', }}></div>
          <div className="faceWind ltWind" style={{ background: isActiveWin4 ? ' rgb(56, 191, 191)' : '', color: isActiveWin4 ? 'white' : '', }}></div>
          <div className="faceWind bmWind" style={{ background: isActiveWin4 ? ' rgb(56, 191, 191)' : '', color: isActiveWin4 ? 'white' : '', }}></div>
          <div className="faceWind tpWind" style={{ background: isActiveWin4 ? ' rgb(56, 191, 191)' : '', color: isActiveWin4 ? 'white' : '', }}></div>
        </div>
        <div className="shapeWind cuboid-5Wind cub-5Wind" onClick={dosome}>
          <div className="faceWind ftWind" style={{ background: isActiveWin5 ? ' rgb(56, 191, 191)' : '', color: isActiveWin5 ? 'white' : '', }}></div>
          <div className="faceWind bkWind" style={{ background: isActiveWin5 ? ' rgb(56, 191, 191)' : '', color: isActiveWin5 ? 'white' : '', }}></div>
          <div className="faceWind rtWind" style={{ background: isActiveWin5 ? ' rgb(56, 191, 191)' : '', color: isActiveWin5 ? 'white' : '', }}></div>
          <div className="faceWind ltWind" style={{ background: isActiveWin5 ? ' rgb(56, 191, 191)' : '', color: isActiveWin5 ? 'white' : '', }}></div>
          <div className="faceWind bmWind" style={{ background: isActiveWin5 ? ' rgb(56, 191, 191)' : '', color: isActiveWin5 ? 'white' : '', }}></div>
          <div className="faceWind tpWind" style={{ background: isActiveWin5 ? ' rgb(56, 191, 191)' : '', color: isActiveWin5 ? 'white' : '', }}></div>
        </div>
        <div className="shapeWind cuboid-6Wind cub-6Wind" onClick={dosome}>
          <div className="faceWind ftWind" style={{ background: isActiveWin6 ? ' rgb(56, 191, 191)' : '', color: isActiveWin6 ? 'white' : '', }}></div>
          <div className="faceWind bkWind" style={{ background: isActiveWin6 ? ' rgb(56, 191, 191)' : '', color: isActiveWin6 ? 'white' : '', }}></div>
          <div className="faceWind rtWind" style={{ background: isActiveWin6 ? ' rgb(56, 191, 191)' : '', color: isActiveWin6 ? 'white' : '', }}></div>
          <div className="faceWind ltWind" style={{ background: isActiveWin6 ? ' rgb(56, 191, 191)' : '', color: isActiveWin6 ? 'white' : '', }}></div>
          <div className="faceWind bmWind" style={{ background: isActiveWin6 ? ' rgb(56, 191, 191)' : '', color: isActiveWin6 ? 'white' : '', }}></div>
          <div className="faceWind tpWind" style={{ background: isActiveWin6 ? ' rgb(56, 191, 191)' : '', color: isActiveWin6 ? 'white' : '', }}></div>
        </div>
        <div className="shapeWind cuboid-7Wind cub-7Wind" onClick={dosome}>
          <div className="faceWind ftWind" style={{ background: isActiveWin7 ? ' rgb(56, 191, 191)' : '', color: isActiveWin7 ? 'white' : '', }}></div>
          <div className="faceWind bkWind" style={{ background: isActiveWin7 ? ' rgb(56, 191, 191)' : '', color: isActiveWin7 ? 'white' : '', }}></div>
          <div className="faceWind rtWind" style={{ background: isActiveWin7 ? ' rgb(56, 191, 191)' : '', color: isActiveWin7 ? 'white' : '', }}></div>
          <div className="faceWind ltWind" style={{ background: isActiveWin7 ? ' rgb(56, 191, 191)' : '', color: isActiveWin7 ? 'white' : '', }}></div>
          <div className="faceWind bmWind" style={{ background: isActiveWin7 ? ' rgb(56, 191, 191)' : '', color: isActiveWin7 ? 'white' : '', }}></div>
          <div className="faceWind tpWind" style={{ background: isActiveWin7 ? ' rgb(56, 191, 191)' : '', color: isActiveWin7 ? 'white' : '', }}></div>
        </div>
        <div className="shapeWind cuboid-1Wind cub-1Wind" onClick={dosome}>
          <div className="faceWind ftWind" style={{ background: isActiveWin ? ' rgb(56, 191, 191)' : '', color: isActiveWin ? 'white' : '', }}></div>
          <div className="faceWind bkWind" style={{ background: isActiveWin ? ' rgb(56, 191, 191)' : '', color: isActiveWin ? 'white' : '', }}></div>
          <div className="faceWind rtWind" style={{ background: isActiveWin ? ' rgb(56, 191, 191)' : '', color: isActiveWin ? 'white' : '', }}></div>
          <div className="faceWind ltWind" style={{ background: isActiveWin ? ' rgb(56, 191, 191)' : '', color: isActiveWin ? 'white' : '', }}></div>
          <div className="faceWind bmWind" style={{ background: isActiveWin ? ' rgb(56, 191, 191)' : '', color: isActiveWin ? 'white' : '', }}></div>
          <div className="faceWind tpWind" style={{ background: isActiveWin ? ' rgb(56, 191, 191)' : '', color: isActiveWin ? 'white' : '', }}></div>
        </div>
        <div className="shapeWind cuboid-8Wind cub-8Wind" onClick={dosome}>
          <div className="faceWind ftWind" style={{ background: isActiveWin8 ? ' rgb(56, 191, 191)' : '', color: isActiveWin8 ? 'white' : '', }}></div>
          <div className="faceWind bkWind" style={{ background: isActiveWin8 ? ' rgb(56, 191, 191)' : '', color: isActiveWin8 ? 'white' : '', }}></div>
          <div className="faceWind rtWind" style={{ background: isActiveWin8 ? ' rgb(56, 191, 191)' : '', color: isActiveWin8 ? 'white' : '', }}></div>
          <div className="faceWind ltWind" style={{ background: isActiveWin8 ? ' rgb(56, 191, 191)' : '', color: isActiveWin8 ? 'white' : '', }}></div>
          <div className="faceWind bmWind" style={{ background: isActiveWin8 ? ' rgb(56, 191, 191)' : '', color: isActiveWin8 ? 'white' : '', }}></div>
          <div className="faceWind tpWind" style={{ background: isActiveWin8 ? ' rgb(56, 191, 191)' : '', color: isActiveWin8 ? 'white' : '', }}></div>
        </div>
        <div className="shapeWind cuboid-9Wind cub-9Wind" onClick={dosome}>
          <div className="faceWind ftWind" style={{ background: isActiveWin9 ? ' rgb(56, 191, 191)' : '', color: isActiveWin9 ? 'white' : '', }}></div>
          <div className="faceWind bkWind" style={{ background: isActiveWin9 ? ' rgb(56, 191, 191)' : '', color: isActiveWin9 ? 'white' : '', }}></div>
          <div className="faceWind rtWind" style={{ background: isActiveWin9 ? ' rgb(56, 191, 191)' : '', color: isActiveWin9 ? 'white' : '', }}></div>
          <div className="faceWind ltWind" style={{ background: isActiveWin9 ? ' rgb(56, 191, 191)' : '', color: isActiveWin9 ? 'white' : '', }}></div>
          <div className="faceWind bmWind" style={{ background: isActiveWin9 ? ' rgb(56, 191, 191)' : '', color: isActiveWin9 ? 'white' : '', }}></div>
          <div className="faceWind tpWind" style={{ background: isActiveWin9 ? ' rgb(56, 191, 191)' : '', color: isActiveWin9 ? 'white' : '', }}></div>
        </div>
      </div>
    </div>
    </Box>
  )
}

export default WindowSec;