import React, { useEffect, useState } from "react";
import { InfluxDB } from "@influxdata/influxdb-client";
import MaObj from "./objects/MaObj";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarExport, GridCsvExportOptions } from '@mui/x-data-grid';
import './scss/Table.scss';
import './scss/Home.scss';
import logo_letter from './img/logo_letter.jpg';
import logo_house from './img/logo_house.jpg';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import BuildIcon from '@mui/icons-material/Build';
import ChartIeq from "./comp/ChartForIEQ";
import MaObjieq from "./objects/MaObjectIEQ";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
//import { format } from "date-fns";
import moment from 'moment';
import WindowSec from "./trials/WindowSec";
import WallTri from "./trials/WallTri";
//import Tooltip from '@mui/material/Tooltip';
//import MySwitch from "./SwitchComp";
// import MeBarChart from "./trials/BarChart_Trial";
// import { now } from "moment";

const token = process.env.REACT_APP_ACCESS_TOKEN;
const org = "INTRA";
//const bucket = "lysis";
const url = "";
let list = [];


const sites = ["Choose a Site", "Kasava", "Terrassa", "VVV"];
const values = ["Choose a Measurement", "Energy Consumption", "Indoor Environmental Quality (IEQ)", "Weather"];
const steps = ["Choose a Step", "Monthly", "Weekly", "Daily", "Hourly", "15 min"];
const types = ["Choose a Data Type", "Raw Data", "Cumulative Data", "Aggregated Data"]


const fields_kasava_energy = ["Choose a Metric", "Electric Energy Export", "Domestic Hot Water Demand", "Energy Common Zones", "Heating Gas Consumption", "Heating Gas Demand"];
const fields_terrassa_energy = ["Choose a Metric", "Electric Energy Export", "Solar Thermal Energy consumed to meet DHW", "Solar Renewable Thermal Energy produced", "Thermal Heating Consumption", "Electric Energy to meet DHW demand", "Electric consumption for mechanical vent"];
const fields_vvv_energy = ["Choose a Metric", "Electric Energy Export", "Electric Energy to meet DHW demand", "Thermal Heating Electric Consumption"];

const fields_global_ieq = ["Choose a Metric", "CO2 Concentration", "Illuminance", "Humidity", "Temperature", "VOC Concentration"];
// const fields_terrassa_ieq=["Choose a Metric", "C02 Concentration", "Illuminance", "Humidity", "Temperature", "VOC Concentration"];
const fields_vvv_ieq = ["Choose a Metric", "CO2 Concentration", "Illuminance", "Humidity", "Temperature", "VOC Concentration", "Radiant Temperature", "Wind Speed"];

const fields_kasava_terrassa_weather = ["Choose a Metric", "Temperature", "Solar Radiation", "Relative Humidity", "Illuminance"];
const fields_vvv_weather = ["Choose a Metric", "Temperature", "Solar Radiation", "Relative Humidity", "UV index"];


const floor = ["Choose an Apartment", "Ground Floor", "First Floor"];
const floor_terrassa = ["Choose an Apartment", "A1.*", "A2.*"];
const floor_VVV = ["Choose an Apartment", "A1", "A2"];

let some = 0;
const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'site', headerName: 'Site', width: 140 },
    { field: '_field', headerName: 'Field', width: 140 },
    { field: '_measurement', headerName: 'Measurement', width: 160 },
    { field: 'apartment', headerName: 'Apartment', width: 140 },
    { field: 'zone', headerName: 'Zone', width: 140 },
    { field: '_value', headerName: 'Value', width: 140 },
    { field: 'timestamp', headerName: 'Timestamp', width: 200 },
];
const columns_energy = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'site', headerName: 'Site', width: 140 },
    { field: '_field', headerName: 'Field', width: 140 },
    { field: '_measurement', headerName: 'Measurement', width: 160 },
    { field: 'apartment', headerName: 'Apartment', width: 140 },
    { field: '_value', headerName: 'Value', width: 140 },
    { field: 'timestamp', headerName: 'Timestamp', width: 200 },
];
const columns_weather = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'site', headerName: 'Site', width: 140 },
    { field: '_field', headerName: 'Field', width: 140 },
    { field: '_measurement', headerName: 'Measurement', width: 160 },
    { field: '_value', headerName: 'Value', width: 140 },
    { field: 'timestamp', headerName: 'Timestamp', width: 200 },
];


function Home() {
    const [info, setInfo] = useState("");
    const [data, setData] = useState([]);
    const [showIEQ, setShowIEQ] = useState("");
    const [bigTable, setBigTable] = useState("No");
    const [fetch, setFetch] = useState("No");
    const [whichSite, setWhichSite] = useState("");
    const [field, setField] = useState([]);
    const [whichMetric, setWhichMetric] = useState("");
    const [whichType, setWhichType] = useState("");
    const [showBut, setShowBut] = useState("");
    const [whichField, setWhichField] = useState("");
    const [selectorVal, setSelectorVal] = useState("");
    const [step, setStep] = useState("");
    const [infoPrint, setInfoPrint] = useState("");
    const [query, setQuery] = useState("");
    const [ieqSearch, setIeqSearch] = useState("");
    const [whichFloor, setWhichFloor] = useState("");
    const [weRender, setWeRender] = useState("");
    const [whichFloorArray, setWhichFloorArray] = useState([]);
    const [theFloorForBuilDing, setTheFloorForBuilding] = useState(floor);
    const [stateColumns, setStateColumns] = useState([]);

    const [startDate, setStartDate] = useState(new Date().setMonth(new Date().getMonth() - 1));
    const [endDate, setEndDate] = useState(new Date());


    const [finalStartDate, setFinalStartDate] = useState("2023-08-01T00:00:00Z");
    const [finalEndDate, setFinalEndDate] = useState("2024-08-01T00:00:00Z");

    const [showDatePick, setShowDatePick] = useState("");
    let queries = [
        //ENERGY Kasava---- Step 1 mo
        //position 0: Elec
        `from(bucket: "lysis") |> range(start: 2022-07-01T00:00:00Z, stop: 2023-09-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "energy_kasava") |> filter(fn: (r) => r["_field"] == "Eelec")  |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)  |> yield(name: "mean")`,
        //position 1: Edhw_e
        `from(bucket: "lysis") |> range(start: 2022-07-01T00:00:00Z, stop: 2023-09-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "energy_kasava") |> filter(fn: (r) => r["_field"] == "Edhw_e") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false) |> yield(name: "mean")`,
        //position 2: E_comm
        `from(bucket: "lysis") |> range(start: 2022-07-01T00:00:00Z, stop: 2023-09-01T00:00:00Z)  |> filter(fn: (r) => r["_measurement"] == "energy_kasava")  |> filter(fn: (r) => r["_field"] == "E_comm")  |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false) |> yield(name: "mean")`,
        //position 3: THGc
        `from(bucket: "lysis") |> range(start: 2022-07-01T00:00:00Z, stop: 2023-09-01T00:00:00Z)  |> filter(fn: (r) => r["_measurement"] == "energy_kasava")  |> filter(fn: (r) => r["_field"] == "THGc")  |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)  |> yield(name: "mean")`,
        //position 4: THGd
        `from(bucket: "lysis")  |> range(start: 2022-07-01T00:00:00Z, stop: 2023-09-01T00:00:00Z)  |> filter(fn: (r) => r["_measurement"] == "energy_kasava")  |> filter(fn: (r) => r["_field"] == "THGd")  |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)  |> yield(name: "mean")`,


        //Energy Terrassa ----- Step 1mo
        //position 5: Elec
        `from(bucket: "lysis") |> range(start: ${finalStartDate}, stop: ${finalEndDate}) |> filter(fn: (r) => r["_measurement"] == "energy_terrassa" and r["_field"] == "Eelec") |> aggregateWindow(every: ${step}, fn: sum, createEmpty: false) |> yield(name: "aggregate")`,
        //`from(bucket: "lysis")  |> range(start: 2022-07-30T00:00:00Z, stop: now())  |> filter(fn: (r) => r["_measurement"] == "energy_terrassa")  |> filter(fn: (r) => r["_field"] == "Eelec")  |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)  |> yield(name: "mean")`,

        //position 6: Edhw_s
        `from(bucket: "lysis")  |> range(start:  ${finalStartDate}Z, stop: ${finalEndDate})  |> filter(fn: (r) => r["_measurement"] == "energy_terrassa")  |> filter(fn: (r) => r["_field"] == "Edhw_st")  |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)  |> yield(name: "mean")`,
        //position 7: Est_prod
        `from(bucket: "lysis")  |> range(start:  ${finalStartDate}Z, stop: ${finalEndDate})  |> filter(fn: (r) => r["_measurement"] == "energy_terrassa")  |> filter(fn: (r) => r["_field"] == "Est_prod")  |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)  |> yield(name: "mean")`,
        //position 8: THc
        `from(bucket: "lysis") |> range(start:  ${finalStartDate}, stop: ${finalEndDate}) |> filter(fn: (r) => r["_measurement"] == "energy_terrassa") |> filter(fn: (r) => r["_field"] == "THc") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false) |> yield(name: "mean")`,
        //position 9: Edhw_e
        `from(bucket: "lysis") |> range(start:  ${finalStartDate}, stop: ${finalEndDate}) |> filter(fn: (r) => r["_measurement"] == "energy_terrassa") |> filter(fn: (r) => r["_field"] == "Edhw_e") |> aggregateWindow(every: ${step}, fn: sum, createEmpty: false) |> yield(name: "aggregate")`,
        //position 10: Efan
        `from(bucket: "lysis") |> range(start:  ${finalStartDate}, stop: ${finalEndDate}) |> filter(fn: (r) => r["_measurement"] == "energy_terrassa") |> filter(fn: (r) => r["_field"] == "Efan") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false) |> yield(name: "mean")`,


        //Energy VVV --- Step 1mo
        //position 11: Elec
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "energy_pre_cum_vvv") |> filter(fn: (r) => r["_field"] == "Eelec")|> aggregateWindow(every: ${step}, fn: last, createEmpty: false) |> difference(nonNegative: true) |> yield(name: "Eelec diff - clean")`,
        //position 12: Edhw_e
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z)  |> filter(fn: (r) => r["_measurement"] == "energy_pre_cum_vvv") |> filter(fn: (r) => r["_field"] == "Edhw_e") |> aggregateWindow(every: ${step}, fn: last, createEmpty: false) |> difference(nonNegative: true) |> yield(name: "Edhw_e diff - clean")`,
        //position 13: Thec
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "energy_pre_cum_vvv") |> filter(fn: (r) => r["_field"] == "THEc") |> aggregateWindow(every: ${step}, fn: last, createEmpty: false) |> difference(nonNegative: true) |> yield(name: "THEc diff - clean")`,

        //KASAVA IEQ
        //position 14: Co2 -- Floor as a Variable (Covers Both Floors)
        `from(bucket: "lysis") |> range(start: 2022-07-01T00:00:00Z, stop: 2023-09-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "ieq_kasava") |> filter(fn: (r) => r["_field"] == "CO2") |> filter(fn: (r) => r["apartment"] == "${whichFloor}") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)`,
        //position 15: Illuminance -- Floor as a Variable (Covers Both Floors)
        `from(bucket: "lysis") |> range(start: 2022-07-01T00:00:00Z, stop: 2023-09-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "ieq_kasava") |> filter(fn: (r) => r["_field"] == "Ev") |> filter(fn: (r) => r["apartment"] == "${whichFloor}") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)`,
        //position 16: Humidity --  Floor as a Variable (Covers Both Floors)
        `from(bucket: "lysis") |> range(start: 2022-07-01T00:00:00Z, stop: 2023-09-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "ieq_kasava") |> filter(fn: (r) => r["_field"] == "RH") |> filter(fn: (r) => r["apartment"] == "${whichFloor}") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)`,
        //position 17: Temperature -- Floor as a Variable (Covers Both Floors)
        `from(bucket: "lysis") |> range(start: 2022-07-01T00:00:00Z, stop: 2023-09-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "ieq_kasava") |> filter(fn: (r) => r["_field"] == "Temp") |> filter(fn: (r) => r["apartment"] == "${whichFloor}") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)`,


        //Terrassa IEQ
        //position 18: Co2
        `from(bucket: "lysis") |> range(start: 2023-08-01T00:00:00Z, stop: 2024-08-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "ieq_terrassa") |> filter(fn: (r) => r["_field"] == "CO2") |> filter(fn: (r) => r["apartment"] == "${whichFloorArray[0]}" or r["apartment"] == "${whichFloorArray[1]}" or r["apartment"] == "${whichFloorArray[2]}") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false) |> yield(name: "mean")`,
        //position 19: Illuminance
        `from(bucket: "lysis") |> range(start: 2023-08-01T00:00:00Z, stop: 2024-08-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "ieq_terrassa") |> filter(fn: (r) => r["_field"] == "Ev") |> filter(fn: (r) => r["apartment"] == "${whichFloorArray[0]}" or r["apartment"] == "${whichFloorArray[1]}" or r["apartment"] == "${whichFloorArray[2]}") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false) |> yield(name: "mean")`,
        //position 20: Humidity
        `from(bucket: "lysis") |> range(start: 2023-08-01T00:00:00Z, stop: 2024-08-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "ieq_terrassa") |> filter(fn: (r) => r["_field"] == "RH") |> filter(fn: (r) => r["apartment"] == "${whichFloorArray[0]}" or r["apartment"] == "${whichFloorArray[1]}" or r["apartment"] == "${whichFloorArray[2]}") |> aggregateWindow(every:  ${step}, fn: mean, createEmpty: false) |> yield(name: "mean")`,
        //position 21:Temperature
        `from(bucket: "lysis") |> range(start: 2023-08-01T00:00:00Z, stop: 2024-08-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "ieq_terrassa") |> filter(fn: (r) => r["_field"] == "Temp") |> filter(fn: (r) => r["apartment"] == "${whichFloorArray[0]}" or r["apartment"] == "${whichFloorArray[1]}" or r["apartment"] == "${whichFloorArray[2]}") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false) |> yield(name: "mean")`,

        //VVV IEQ
        //position 22: Co2
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "ieq_vvv") |> filter(fn: (r) => r["_field"] == "CO2")|> filter(fn: (r) => r["apartment"] == "${whichFloor}") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false) |> yield(name: "mean")`,
        //position 23: Humidity
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "ieq_vvv") |> filter(fn: (r) => r["_field"] == "RH") |> filter(fn: (r) => r["apartment"] == "${whichFloor}") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false) |> yield(name: "mean")`,

        //LATER ON ADDED
        //position 24: VOC terrassa
        `from(bucket: "lysis") |> range(start: 2023-08-01T00:00:00Z, stop: 2024-08-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "ieq_terrassa") |> filter(fn: (r) => r["_field"] == "VOC") |> filter(fn: (r) => r["apartment"] == "${whichFloorArray[0]}" or r["apartment"] == "${whichFloorArray[1]}" or r["apartment"] == "${whichFloorArray[2]}") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false) |> yield(name: "mean")`,
        //position 25: VoC Kasava
        `from(bucket: "lysis") |> range(start: 2022-07-01T00:00:00Z, stop: 2023-09-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "ieq_kasava") |> filter(fn: (r) => r["_field"] == "VOC") |> filter(fn: (r) => r["apartment"] == "${whichFloor}") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)`,

        //position 26: MRT VVV
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "ieq_vvv") |> filter(fn: (r) => r["_field"] == "MRT") |> filter(fn: (r) => r["apartment"] == "${whichFloor}") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false) |> yield(name: "mean")`,
        //position 27: VoC VVV
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "ieq_vvv") |> filter(fn: (r) => r["_field"] == "VOC") |> filter(fn: (r) => r["apartment"] == "${whichFloor}") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false) |> yield(name: "mean")`,
        //position 28: WindSpeed VVV
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "ieq_vvv") |> filter(fn: (r) => r["_field"] == "WS") |> filter(fn: (r) => r["apartment"] == "${whichFloor}") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false) |> yield(name: "mean")`,
        //position 29: Temperature VVV
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "ieq_vvv") |> filter(fn: (r) => r["_field"] == "Temp") |> filter(fn: (r) => r["apartment"] == "${whichFloor}") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false) |> yield(name: "mean")`,

        //position 30: Weather KASAVA  Temp_out
        `from(bucket: "lysis") |> range(start: 2022-07-01T00:00:00Z, stop: 2023-09-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "weather_kasava" and r["_field"] == "Temp_out")|> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)|> yield(name: "mean")`,
        //position 31: Weather KASAVA  Sol_rad
        `from(bucket: "lysis") |> range(start: 2022-07-01T00:00:00Z, stop: 2023-09-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "weather_kasava" and r["_field"] == "SolRad")|> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)|> yield(name: "mean")`,
        //position 32: Weather Kasava  RH_out
        `from(bucket: "lysis") |> range(start: 2022-07-01T00:00:00Z, stop: 2023-09-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "weather_kasava" and r["_field"] == "RH_out")|> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)|> yield(name: "mean")`,
        //position 33: Weather Kasava  Ev_out
        `from(bucket: "lysis") |> range(start: 2022-07-01T00:00:00Z, stop: 2023-09-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "weather_kasava" and r["_field"] == "Ev_out")|> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)|> yield(name: "mean")`,


        //position 34: Weather Terrassa  Temp_out
        `from(bucket: "lysis") |> range(start: 2023-08-01T00:00:00Z, stop: 2024-08-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "weather_terrassa" and r["_field"] == "Temp_out")|> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)|> yield(name: "mean")`,
        //position 35: Weather Terrassa  Sol_rad
        `from(bucket: "lysis") |> range(start: 2023-08-01T00:00:00Z, stop: 2024-08-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "weather_terrassa" and r["_field"] == "SolRad")|> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)|> yield(name: "mean")`,
        //position 36: Weather Terrassa  RH_out
        `from(bucket: "lysis") |> range(start: 2023-08-01T00:00:00Z, stop: 2024-08-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "weather_terrassa" and r["_field"] == "RH_out")|> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)|> yield(name: "mean")`,
        //position 37: Weather Terrassa  Ev_out
        `from(bucket: "lysis") |> range(start: 2023-08-01T00:00:00Z, stop: 2024-08-01T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "weather_terrassa" and r["_field"] == "Ev_out")|> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)|> yield(name: "mean")`,

        //position 38: Weather VVV  Temp_out
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "weather_vvv" and r["_field"] == "Temp_out")|> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)|> yield(name: "mean")`,
        //position 39: Weather VVV  Sol_rad
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "weather_vvv" and r["_field"] == "SolRad")|> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)|> yield(name: "mean")`,
        //position 40: Weather VVV  RH_out
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "weather_vvv" and r["_field"] == "RH_out")|> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)|> yield(name: "mean")`,
        //position 41: Weather VVV UV_idx
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "weather_vvv" and r["_field"] == "UV_idx")|> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)|> yield(name: "mean")`,
    
        //POSITION 42--vvv raw and electric energy export
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "energy_vvv") |> filter(fn: (r) => r["_field"] == "Eelec") |> yield(name: "Eelec - raw")`,
        //POSITION 43--vvv raw and electric energy DHW
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "energy_vvv") |> filter(fn: (r) => r["_field"] == "Edhw_e") |> yield(name: "Edhw_e - raw")`,
        //POSITION 44--vvv raw and heating consumption
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "energy_vvv") |> filter(fn: (r) => r["_field"] == "THEc") |> yield(name: "THPEc - raw")`,
       

        //POSITION 45--vvv Cum and electric energy export
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "energy_pre_cum_vvv") |> filter(fn: (r) => r["_field"] == "Eelec") |> yield(name: "Eelec - cumulative")`,
        //POSITION 46--vvv Cum and electric energy DHW
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "energy_pre_cum_vvv") |> filter(fn: (r) => r["_field"] == "Edhw_e") |> yield(name: "Edhw_e cumulative - clean")`,
        //POSITION 47--vvv Cum and heating Consumption
        `from(bucket: "lysis") |> range(start: 2022-07-30T00:00:00Z, stop: 2023-07-31T00:00:00Z) |> filter(fn: (r) => r["_measurement"] == "energy_pre_cum_vvv") |> filter(fn: (r) => r["_field"] == "THEc") |> yield(name: "THPEc cumulative - clean")`,
    
    ];


    let terrassaQueries =
        [
            `from(bucket: "lysis") |> range(start: ${finalStartDate}, stop: ${finalEndDate}) |> filter(fn: (r) => r["_measurement"] == "energy_terrassa" and r["_field"] == "Eelec") |> aggregateWindow(every: ${step}, fn: sum, createEmpty: false) |> yield(name: "aggregate")`,
            //`from(bucket: "lysis")  |> range(start: 2022-07-30T00:00:00Z, stop: now())  |> filter(fn: (r) => r["_measurement"] == "energy_terrassa")  |> filter(fn: (r) => r["_field"] == "Eelec")  |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)  |> yield(name: "mean")`,
            //position 6: Edhw_s
            `from(bucket: "lysis") |> range(start:  ${finalStartDate}, stop: ${finalEndDate})  |> filter(fn: (r) => r["_measurement"] == "energy_terrassa")  |> filter(fn: (r) => r["_field"] == "Edhw_st")  |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)  |> yield(name: "mean")`,
            //position 7: Est_prod
            `from(bucket: "lysis") |> range(start:  ${finalStartDate}, stop: ${finalEndDate})  |> filter(fn: (r) => r["_measurement"] == "energy_terrassa")  |> filter(fn: (r) => r["_field"] == "Est_prod")  |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false)  |> yield(name: "mean")`,
            //position 8: THc
            `from(bucket: "lysis") |> range(start:  ${finalStartDate}, stop: ${finalEndDate}) |> filter(fn: (r) => r["_measurement"] == "energy_terrassa") |> filter(fn: (r) => r["_field"] == "THc") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false) |> yield(name: "mean")`,
            //position 9: Edhw_e
            `from(bucket: "lysis") |> range(start:  ${finalStartDate}, stop: ${finalEndDate}) |> filter(fn: (r) => r["_measurement"] == "energy_terrassa") |> filter(fn: (r) => r["_field"] == "Edhw_e") |> aggregateWindow(every: ${step}, fn: sum, createEmpty: false) |> yield(name: "aggregate")`,
            //position 10: Efan
            `from(bucket: "lysis") |> range(start:  ${finalStartDate}, stop: ${finalEndDate}) |> filter(fn: (r) => r["_measurement"] == "energy_terrassa") |> filter(fn: (r) => r["_field"] == "Efan") |> aggregateWindow(every: ${step}, fn: mean, createEmpty: false) |> yield(name: "mean")`,
        ];

    useEffect(() => {
        console.log(startDate)
        console.log(endDate)
        setFinalStartDate(moment(startDate).toISOString());
        setFinalEndDate(moment(endDate).toISOString());
        console.log(moment(endDate).diff(moment(startDate), 'days'))
        var difference = moment(endDate).diff(moment(startDate), 'days')
        if (difference > 31) {
            console.log(moment(endDate).subtract(1, 'months')._d)
            setStartDate(moment(endDate).subtract(1, 'months')._d)
        }
        if (difference <= 31) {
            console.log("OK")
        }
    }, [startDate, endDate])

    useEffect(() => {
        console.log(finalEndDate)
        console.log(finalStartDate)
        if (whichSite === "Terrassa") {
            if (whichMetric === "Energy Consumption") {//&& (step==="1w" || step==="1mo" || step==="1d")
                if (whichField === fields_terrassa_energy[1]) { setShowBut("OK"); setQuery(terrassaQueries[1]); }
                if (whichField === fields_terrassa_energy[2]) { setShowBut("OK"); setQuery(terrassaQueries[6]); }
                if (whichField === fields_terrassa_energy[3]) { setShowBut("OK"); setQuery(terrassaQueries[7]); }
                if (whichField === fields_terrassa_energy[4]) { setShowBut("OK"); setQuery(terrassaQueries[8]); }
                if (whichField === fields_terrassa_energy[5]) { setShowBut("OK"); setQuery(terrassaQueries[9]); }
                if (whichField === fields_terrassa_energy[6]) { setShowBut("OK"); setQuery(terrassaQueries[10]); }
            }
        }
    }, [finalEndDate, finalStartDate])

    function handleChangeStart(e) {
        setStartDate(e)
        // handleTheFinalDate();
    }
    function handleChangeEnd(e) {
        setEndDate(e)
        // handleTheFinalDate();
    }
    function finalizeTheDate() {

        console.log("WE are to finalizwe the date")
        setFinalStartDate(moment(startDate).toISOString());
        setFinalEndDate(moment(endDate).toISOString());
        console.log(finalStartDate, finalEndDate)
    }



    // function that is called in order to fetch the required data from LYSIS
    const influxQuery = async () => {
        const queryApi = await new InfluxDB({ url, token }).getQueryApi(org);
        list = [];
        console.log(query)
        queryApi.queryRows(query, {
            next(row, tableMeta) {
                const o = tableMeta.toObject(row);
                some++;

                if (o.zone !== "") {
                    if (o._measurement.includes("ieq"))
                        list.push(new MaObjieq(some, o.table, o.site, o._field, "IEQ", o.apartment, o.zone, o._value.toFixed(2), o._time));
                    if (o._measurement.includes("energy"))
                        list.push(new MaObjieq(some, o.table, o.site, o._field, "Energy Consumption", o.apartment, o.zone, o._value.toFixed(2), o._time));
                    if (o._measurement.includes("weather"))
                        list.push(new MaObjieq(some, o.table, o.site, o._field, "Weather", o.apartment, o.zone, o._value.toFixed(2), o._time));

                }
                else {
                    list.push(new MaObj(some, o.table, o.site, o._field, o._measurement, o.apartment, o._value.toFixed(2), o._time));
                }
            },
            complete() {
                some = 0;
                setData([...list]);
                if (step === "1mo" || step === "1w" || step === "1d") { setFetch("OK"); }
                else {
                    setFetch("No");
                }

            },
            error(error) {
                console.log(error, "AN ERROR");
            },
        })
    }




    const handleSubmit = () => {
        if (step === "1mo" || step === "1w" || step === "1d") {
            setBigTable("No");
        }
        else {
            setBigTable("Yes");
            //console.log(moment(startDate).toISOString(),moment(endDate).toISOString())

        }
        if (whichMetric === "Weather") {
            setStateColumns([...columns_weather]);
        }
        if (whichMetric === "Indoor Environmental Quality (IEQ)") {
            setStateColumns([...columns]);
        }
        if (whichMetric === "Energy Consumption") {
            setStateColumns([...columns_energy]);
        }


        setInfo("Site: " + whichSite + ", Field: " + whichField + ", Measurement: " + whichMetric +  (whichSite==="VVV" && whichMetric==="Energy Consumption" ?(whichType === "Cum" ? " ,Type: " +"Cumulative ": " ,Type: " +whichType):"") + (whichSite!=="VVV" || (whichSite==="VVV" && whichMetric!=="Energy Consumption") ? ", Step: " + step :(whichType === "Aggregated" ? ", Step: " + step : "")));
        setInfoPrint(whichSite + " " + whichField + " " + whichMetric + " " + " " + (whichType === "Cum" ? "Cumulative ": whichType) + (whichType === "Aggregated" ? step : ""))
        setWhichField("");
        setShowBut("");
        setWhichMetric("");
        setWhichSite("");
        setSelectorVal(0);
        influxQuery();
        setWhichType("");
        setStep("");
        setWeRender("OK");


    }

    //Selecting the Site (i.e. VVV, Kasava, Terrassa)
    const selectSite = (e) => {
        setSelectorVal(e.target.value);
        setWhichSite(e.target.value);
        setWeRender("");
    }
    //Selecting the Metric in Question (i.e. Energy Consumption OR IEQ)
    const selectMetric = (e) => {
        setWhichMetric(e.target.value);

        if (whichSite === "Kasava") {
            if (whichMetric === "Energy Consumption") { setField([...fields_kasava_energy]); setIeqSearch(""); }
        }
        if (whichSite === "Terrassa") {
            if (whichMetric === "Energy Consumption") { setField([...fields_terrassa_energy]); setIeqSearch(""); }
        }
        if (whichSite === "VVV") {
            if (whichMetric === "Energy Consumption") { setField([...fields_vvv_energy]); setIeqSearch(""); }
        }

        if (whichMetric === "Indoor Environmental Quality (IEQ)") {
            setField([...fields_global_ieq]);
            setIeqSearch("IEQ");
        }
        if (whichMetric === "Indoor Environmental Quality (IEQ)" && whichSite === "VVV") {
            setField([...fields_vvv_ieq]);
            setIeqSearch("IEQ");
        }

        if (whichMetric === "Weather") {
            setField([...fields_kasava_terrassa_weather]);
            setIeqSearch("");
        }
        if (whichMetric === "Weather" && whichSite === "VVV") {
            setField([...fields_vvv_weather]);
            setIeqSearch("");
        }


    }

    //Selecting the Field (i.e. CO2, illuminance, Energy Export etc)--> Then execute the appropriate query
    const selectField = (e) => {
        setWhichField(e.target.value);

        if (whichSite === "Kasava") {
            if (whichMetric === "Energy Consumption") {
                if (whichField === fields_kasava_energy[1]) { setShowBut("OK"); setQuery(queries[0]); }
                if (whichField === fields_kasava_energy[2]) { setShowBut("OK"); setQuery(queries[1]); }
                if (whichField === fields_kasava_energy[3]) { setShowBut("OK"); setQuery(queries[2]); }
                if (whichField === fields_kasava_energy[4]) { setShowBut("OK"); setQuery(queries[3]); }
                if (whichField === fields_kasava_energy[5]) { setShowBut("OK"); setQuery(queries[4]); }
            }
            if (whichMetric === "Indoor Environmental Quality (IEQ)") {
                if (whichField === fields_global_ieq[1]) { setShowBut("OK"); setQuery(queries[14]); }
                if (whichField === fields_global_ieq[2]) { setShowBut("OK"); setQuery(queries[15]); }
                if (whichField === fields_global_ieq[3]) { setShowBut("OK"); setQuery(queries[16]); }
                if (whichField === fields_global_ieq[4]) { setShowBut("OK"); setQuery(queries[17]); }
                if (whichField === fields_global_ieq[5]) { setShowBut("OK"); setQuery(queries[25]); }
            }
            if (whichMetric === "Weather") {
                if (whichField === fields_kasava_terrassa_weather[1]) { setShowBut("OK"); setQuery(queries[30]); }
                if (whichField === fields_kasava_terrassa_weather[2]) { setShowBut("OK"); setQuery(queries[31]); }
                if (whichField === fields_kasava_terrassa_weather[3]) { setShowBut("OK"); setQuery(queries[32]); }
                if (whichField === fields_kasava_terrassa_weather[4]) { setShowBut("OK"); setQuery(queries[33]); }

            }

        }
        if (whichSite === "Terrassa") {
            if (whichMetric === "Energy Consumption" && (step === "1w" || step === "1mo" || step === "1d")) {//&& (step==="1w" || step==="1mo" || step==="1d")
                if (whichField === fields_terrassa_energy[1]) { setShowBut("OK"); setQuery(queries[5]); }
                if (whichField === fields_terrassa_energy[2]) { setShowBut("OK"); setQuery(queries[6]); }
                if (whichField === fields_terrassa_energy[3]) { setShowBut("OK"); setQuery(queries[7]); }
                if (whichField === fields_terrassa_energy[4]) { setShowBut("OK"); setQuery(queries[8]); }
                if (whichField === fields_terrassa_energy[5]) { setShowBut("OK"); setQuery(queries[9]); }
                if (whichField === fields_terrassa_energy[6]) { setShowBut("OK"); setQuery(queries[10]); }
            }
            // if (whichMetric === "Energy Consumption" && (step==="15m" || step==="1h") ) {
            //     if (whichField === fields_terrassa_energy[1]) { finalizeTheDate(); setQuery(queries[5]); }
            //     if (whichField === fields_terrassa_energy[2]) {finalizeTheDate();  setQuery(queries[6]); }
            //     if (whichField === fields_terrassa_energy[3]) {finalizeTheDate(); setQuery(queries[7]); }
            //     if (whichField === fields_terrassa_energy[4]) {finalizeTheDate();setQuery(queries[8]); }
            //     if (whichField === fields_terrassa_energy[5]) {finalizeTheDate(); setQuery(queries[9]); }
            //     if (whichField === fields_terrassa_energy[6]) {finalizeTheDate();  setQuery(queries[10]); }
            // }
            if (whichMetric === "Indoor Environmental Quality (IEQ)") {
                if (whichField === fields_global_ieq[1]) { setShowBut("OK"); setQuery(queries[18]); }
                if (whichField === fields_global_ieq[2]) { setShowBut("OK"); setQuery(queries[19]); }
                if (whichField === fields_global_ieq[3]) { setShowBut("OK"); setQuery(queries[20]); }
                if (whichField === fields_global_ieq[4]) { setShowBut("OK"); setQuery(queries[21]); }
                if (whichField === fields_global_ieq[5]) { setShowBut("OK"); setQuery(queries[24]); }
            }
            if (whichMetric === "Weather") {
                if (whichField === fields_kasava_terrassa_weather[1]) { setShowBut("OK"); setQuery(queries[34]); }
                if (whichField === fields_kasava_terrassa_weather[2]) { setShowBut("OK"); setQuery(queries[35]); }
                if (whichField === fields_kasava_terrassa_weather[3]) { setShowBut("OK"); setQuery(queries[36]); }
                if (whichField === fields_kasava_terrassa_weather[4]) { setShowBut("OK"); setQuery(queries[37]); }
            }
        }
        if (whichSite === "VVV") {
            if (whichMetric === "Energy Consumption") {
                if (whichType === "Aggregated") {
                    if (whichField === fields_vvv_energy[1]) { setShowBut("OK"); setQuery(queries[11]); }
                    if (whichField === fields_vvv_energy[2]) { setShowBut("OK"); setQuery(queries[12]); }
                    if (whichField === fields_vvv_energy[3]) { setShowBut("OK"); setQuery(queries[13]); }
                }
                if (whichType === "Raw") {
                    if (whichField === fields_vvv_energy[1]) { setShowBut("OK"); setQuery(queries[42]); }
                    if (whichField === fields_vvv_energy[2]) { setShowBut("OK"); setQuery(queries[43]); }
                    if (whichField === fields_vvv_energy[3]) { setShowBut("OK"); setQuery(queries[44]); }
                }
                if (whichType === "Cum") {
                    if (whichField === fields_vvv_energy[1]) { setShowBut("OK"); setQuery(queries[45]); }
                    if (whichField === fields_vvv_energy[2]) { setShowBut("OK"); setQuery(queries[46]); }
                    if (whichField === fields_vvv_energy[3]) { setShowBut("OK"); setQuery(queries[47]); }
                }
            }
            if (whichMetric === "Indoor Environmental Quality (IEQ)") {
                if (whichField === fields_vvv_ieq[1]) { setShowBut("OK"); setQuery(queries[22]); }
                if (whichField === fields_vvv_ieq[3]) { setShowBut("OK"); setQuery(queries[23]); }
                if (whichField === fields_vvv_ieq[4]) { setShowBut("OK"); setQuery(queries[29]); }
                if (whichField === fields_vvv_ieq[5]) { setShowBut("OK"); setQuery(queries[27]); }
                if (whichField === fields_vvv_ieq[6]) { setShowBut("OK"); setQuery(queries[26]); }
                if (whichField === fields_vvv_ieq[7]) { setShowBut("OK"); setQuery(queries[28]); }
            }
            if (whichMetric === "Weather") {
                if (whichField === fields_vvv_weather[1]) { setShowBut("OK"); setQuery(queries[38]); }
                if (whichField === fields_vvv_weather[2]) { setShowBut("OK"); setQuery(queries[39]); }
                if (whichField === fields_vvv_weather[3]) { setShowBut("OK"); setQuery(queries[40]); }
                if (whichField === fields_vvv_weather[4]) { setShowBut("OK"); setQuery(queries[41]); }
            }
        }
    }

    //Selecting the Step by which the data are retrieved (i.e. Monthly, Weekly, Daily etc)
    const selectStep = (e) => {
        if (e.target.value === "Monthly") {
            setStep('1mo');
        }
        if (e.target.value === "Weekly") {
            setStep('1w');
        }
        if (e.target.value === "Hourly") {
            setStep('1h');
        }
        if (e.target.value === "Daily") {
            setStep('1d');
        }
        if (e.target.value === "15 min") {
            setStep('15m');
        }
    }
    const selectType = (e) => {
        if (e.target.value === "Raw Data") {
            setWhichType('Raw');
        }
        if (e.target.value === "Cumulative Data") {
            setWhichType("Cum")
        }
        if (e.target.value === "Aggregated Data") {
            setWhichType("Aggregated")
        }

    }

    //Selecting the Floor (i.e. A1, A2, GroundFloor, FirstFloor)
    const selectFloor = (e) => {
        if (whichSite === "Kasava") {
            setTheFloorForBuilding([...floor]);
            if (e.target.value === "Ground Floor") {
                setWhichFloor('GroundFloor');
            }
            if (e.target.value === "First Floor") {
                setWhichFloor('FirstFloor');
            }
        }
        if (whichSite === "Terrassa") {
            setTheFloorForBuilding([...floor_terrassa]);
            if (e.target.value === "A1.*") {
                setWhichFloorArray(["A1.1", "A1.5", "A1.6"]);
            }
            if (e.target.value === "A2.*") {
                setWhichFloorArray(["A2.3", "A2.5", "A2.6"]);
            }
        }
        if (whichSite === "VVV") {
            setTheFloorForBuilding([...floor_VVV]);
            if (e.target.value === "A1") {
                setWhichFloor("A1");
            }
            if (e.target.value === "A2") {
                setWhichFloor("A2");
            }
        }

    }
    //used to initialize the Keycloak Integration
    const { keycloak, initialized } = useKeycloak();

    //Used in Routing between different parts of the App (i.e. MODEST , Visualization)
    const navigate = useNavigate();



    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    csvOptions={{
                        fileName: "Name",
                    }} />
            </GridToolbarContainer>
        );
    }



    return (
        <div className="home_main_div">

            <div className="sidebar_unfetched"><div className="logo"><li><img src={logo_letter} alt={"plural_logo"} /><img src={logo_house} alt={"plural_logo"} /></li></div>
                <h5>MAIN</h5>
                <li onClick={() => navigate("/modest")}><BuildIcon className="mui_icon" />MODEST</li>
                <li onClick={() => navigate("/home")} style={{ color: "white", backgroundColor: "rgba(4,188,183,255)" }}><EqualizerIcon className="mui_icon" style={{ color: "white" }} /> Site Monitoring</li>
                <h5>USER</h5>
                <li><AccountBoxIcon className="mui_icon" /> Profile</li>
                {!!keycloak.authenticated && (<li onClick={() => { navigate("/"); keycloak.logout() }}><LogoutIcon className="mui_icon" />Logout ({keycloak.tokenParsed.preferred_username})</li>)}
            </div>
            <div className="inner_home_div">
                <div style={{ marginLeft: "1000px", heigh: "200px" }}></div>
                <div style={{ display: "flex", boxShadow: "1px 1px 10px 1px rgba(130, 130, 130, 0.83)", margin: "2px" }}>
                    <div style={{ cursor: "pointer", borderRadius: "5px 5px 0px 0px", margin: "2px 10px 0px 2px", background: "rgb(56, 191, 191)", color: "white", border: "1px solid grey", padding: "5px 4px 5px 4px" }}>Pre-Renovation Phase</div>
                    <div onClick={() => navigate("/postreno")} style={{ borderRadius: "5px 5px 0px 0px", margin: "2px 2px 0px 2px", background: "rgb(56, 191, 191, 0.5)", color: "white", border: "1px solid rgba(129, 129, 129, 0.7)", padding: "5px 4px 5px 4px", cursor: "pointer" }}>Post-Renovation Phase</div>
                </div>
                <div className="selector">
                    <div>
                        <label>Sites </label>
                        <select onChange={selectSite} value={selectorVal}>
                            {sites.map((site) => (
                                <option key={site}>{site}</option>))}
                        </select>
                    </div>
                    {whichSite !== "" && whichSite !== "Choose a Site" ? <div>
                        <label>Measurement </label>
                        <select onClick={selectMetric} onChange={selectMetric}>
                            {values.map((value) => (
                                <option key={value}>{value}</option>))}
                        </select>
                    </div> : <div></div>}

                    {ieqSearch === "IEQ" && whichSite !== "" && whichSite !== "Choose a Site" ? <div>
                        <label>Apartment </label>
                        <select onClick={selectFloor} onChange={selectFloor}  >
                            {theFloorForBuilDing.map((fl) => (
                                <option key={fl}>{fl}</option>))}
                        </select>
                    </div> : <div></div>}
                    {whichMetric !== "" && whichMetric !== "Choose a Metric" && whichSite==="VVV" && whichMetric ==="Energy Consumption"? <div>
                        <label>Type </label>
                        {/* onClick={selectStep2} onChange={selectStep} */}
                        <select onClick={selectType} onChange={selectType}  >
                            {types.map((type) => (
                                <option key={type}>{type}</option>))}
                        </select>
                    </div> : <div></div>}
                    {(whichType === "Aggregated" && whichSite==="VVV") || (whichMetric !=="Energy Consumption" && whichSite !=="") || (whichSite!=="VVV" && whichSite!=="") ? <div>
                        <label>Step </label>
                        {/* onClick={selectStep2} onChange={selectStep} */}
                        <select onClick={selectStep} onChange={selectStep}  >
                            {steps.map((stepi) => (
                                <option key={stepi}>{stepi}</option>))}
                        </select>
                    </div> : <div></div>}
                    {(step !== "" && step !== "Choose a Step") || (whichType !== "Aggregated" && whichType !== "") ?
                        <div>
                            <label>Metrics </label>
                            <select onClick={selectField} onChange={selectField}  >
                                {field.map((ar) => (
                                    <option key={ar}>{ar}</option>))}
                            </select>
                        </div> : <div></div>}

                    {whichField !== "" && whichField !== "Choose a Metric" && (step === "1h" || step === "15m") && whichSite === "Terrassa" && whichMetric === "Energy Consumption" ?
                        <><LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                renderInput={(props) => <TextField {...props} />}
                                label="Start Date"
                                value={startDate}
                                name="startDate"
                                onChange={handleChangeStart}
                                sx={{ marginLeft: 0.5, marginTop: 2 }}
                            />
                        </LocalizationProvider>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    label="End Date"
                                    value={endDate}
                                    name="endDate"
                                    onChange={handleChangeEnd}
                                    sx={{ marginLeft: 0.5, marginTop: 2 }}
                                    maxDate={new Date()}
                                />
                            </LocalizationProvider></> : <></>}



                    {showBut === "OK" ? <button onClick={handleSubmit}>Submit</button> : <div></div>}

                </div>
                {info !== "" ? <div className="info">{info}</div> : <div></div>}
                <div className="table">
                    {bigTable === "No" ? <div style={{ height: 600, width: '100%' }}>
                        <DataGrid
                            rows={data}
                            columns={stateColumns}
                            pageSize={10}
                            rowsPerPageOptions={[50]}
                            checkboxSelection
                            components={{ Toolbar: GridToolbar }}
                            componentsProps={{ toolbar: { csvOptions: { fileName: `${info}` } } }}
                        />
                    </div> :
                        <div style={{ height: "88vh", width: '100%' }}>
                            <DataGrid
                                rows={data}
                                columns={stateColumns}
                                pageSize={40}
                                rowsPerPageOptions={[50]}
                                checkboxSelection
                                components={{ Toolbar: GridToolbar }}
                                componentsProps={{ toolbar: { csvOptions: { fileName: `${info}` } } }}
                            />
                        </div>}
                </div>

                <div className="chart_div">
                    <div className="chart_div_inner">
                        <ChartIeq theArray={data} chartfetch={fetch} weRender={weRender} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Home;