// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cube-containerC {
    width: 200px;
    height: 200px;
    position: relative;
    margin: 50px auto;
    perspective: 1000px; /* Enables 3D effect */
    cursor: grab; /* Show a grabbing cursor */
  }
  
  .cubeC {
    width: 100%;
    height: 100%;
    position: absolute;
    transform-style: preserve-3d; /* Keeps the faces in 3D space */
    transition: transform 0.1s ease; /* Smooth transition */
  }
  
  .faceC {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 165, 0, 0.8); /* Semi-transparent orange */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    color: white;
    border: 2px solid #fff; /* White borders around the cube */
  }
  
  .frontC { transform: rotateY(0deg) translateZ(100px); }
  .backC { transform: rotateY(180deg) translateZ(100px); }
  .leftC { transform: rotateY(-90deg) translateZ(100px); }
  .rightC { transform: rotateY(90deg) translateZ(100px); }
  .topC { transform: rotateX(90deg) translateZ(100px); }
  .bottomC{ transform: rotateX(-90deg) translateZ(100px); }
  `, "",{"version":3,"sources":["webpack://./src/trials/Cube.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,iBAAiB;IACjB,mBAAmB,EAAE,sBAAsB;IAC3C,YAAY,EAAE,2BAA2B;EAC3C;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,4BAA4B,EAAE,gCAAgC;IAC9D,+BAA+B,EAAE,sBAAsB;EACzD;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,kCAAkC,EAAE,4BAA4B;IAChE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;IAChB,YAAY;IACZ,sBAAsB,EAAE,kCAAkC;EAC5D;;EAEA,UAAU,0CAA0C,EAAE;EACtD,SAAS,4CAA4C,EAAE;EACvD,SAAS,4CAA4C,EAAE;EACvD,UAAU,2CAA2C,EAAE;EACvD,QAAQ,2CAA2C,EAAE;EACrD,UAAU,4CAA4C,EAAE","sourcesContent":[".cube-containerC {\n    width: 200px;\n    height: 200px;\n    position: relative;\n    margin: 50px auto;\n    perspective: 1000px; /* Enables 3D effect */\n    cursor: grab; /* Show a grabbing cursor */\n  }\n  \n  .cubeC {\n    width: 100%;\n    height: 100%;\n    position: absolute;\n    transform-style: preserve-3d; /* Keeps the faces in 3D space */\n    transition: transform 0.1s ease; /* Smooth transition */\n  }\n  \n  .faceC {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    background: rgba(255, 165, 0, 0.8); /* Semi-transparent orange */\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 1.5em;\n    color: white;\n    border: 2px solid #fff; /* White borders around the cube */\n  }\n  \n  .frontC { transform: rotateY(0deg) translateZ(100px); }\n  .backC { transform: rotateY(180deg) translateZ(100px); }\n  .leftC { transform: rotateY(-90deg) translateZ(100px); }\n  .rightC { transform: rotateY(90deg) translateZ(100px); }\n  .topC { transform: rotateX(90deg) translateZ(100px); }\n  .bottomC{ transform: rotateX(-90deg) translateZ(100px); }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
