import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import BuildIcon from '@mui/icons-material/Build';
import logo_letter from './img/logo_letter.jpg';
import logo_house from './img/logo_house.jpg';
import LogoutIcon from '@mui/icons-material/Logout';
import './scss/Modest.scss';
import '@inovua/reactdatagrid-community/index.css'
import { Button } from 'primereact/button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
//*************** */ Prime REACT PART **************//
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import Swal from 'sweetalert2'
import 'primereact/resources/themes/lara-light-teal/theme.css';//GOOD FOR PLURALLLL
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";
import { Ripple } from 'primereact/ripple';
import { MultiSelect } from 'primereact/multiselect';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import SmartWall from "./trials/SmartWall";
import InfoPnUCard from "./trials/InfoPnUCard";
import PnUObjNew from "./objects/PnUObjNew";
import PnUObjHybrid from "./objects/PnUObjHybrid";
import PnUObjConEx from "./objects/PnUObjConEx";
import { Hidden } from "@mui/material";
import PnUObjSmart from "./objects/PnUObjSmart";
import { string } from "@inovua/reactdatagrid-community/filterTypes";
import HybridWall from "./trials/HybridWall";
import WindowSec from "./trials/WindowSec";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import ConExWall from "./trials/ConExWall";
//**************************UnUsed Libraries************************************/
// import * as xlsx from 'xlsx';
// import excelFile from './simplerJordi_Data - Copy-Round.xlsx';
// import ReactDataGrid from '@inovua/reactdatagrid-community'
// import Tooltip from '@mui/material/Tooltip';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import MaObjieq from "./objects/MaObjectIEQ";
// import MaObj from "./objects/MaObj";
// import { read, utils, excel } from 'xlsx';
//import 'primereact/resources/themes/lara-light-indigo/theme.css';
//import Button from '@inovua/reactdatagrid-community/packages/Button';
// import CoupledStaff3D from "./trials/CoupledStaff3D";
//import "primereact/resources/themes/fluent-light/theme.css";
//import "primereact/resources/themes/mdc-light-indigo/theme.css";  //theme


const SEPARATOR = ',';


function Modest() {
    const [showModels, setShowModels] = useState(false);
    const [data, setData] = useState([]);
    const [hideArr, setHideArr] = useState(false);
    const [filteredData, setFilteredData] = useState(data); // Initialize with original data
    const [whatWeFetch, setWhatWeFetch] = useState("");
    const sites = ["None", "TERRASSA", "KASAVA", "Berlin", "VVV"];
    const modellers = ["None", "IREC", "SPF", "NTUA"];
    const kits = ["None", "HybridWall", "ConExWall", "SmartWall"];

    //***********PRIME REACT PART***********/

    const columnInitialization =
        [
            { field: 'kit_id', header: 'ID', dataType: 'numeric' },
            { field: 'kit', header: 'kit' },
            { field: 'kit_name', header: 'kit name', style: { minWidth: '300px' } },
            { field: 'operation_mode', header: 'operation mode' },
            { field: 'pv_area', header: 'PV area' },
            { field: 'shading', header: 'shading' },
            { field: 'thermal_insulation', header: 'thermal insulation' },
            { field: 'tiles', header: 'tiles' },
            { field: 'window_frame', header: 'window frame' },
            { field: 'window_glazing', header: 'window glazing' },
            { field: 'insulation_thickness', header: 'insulation thickness' },
            { field: 'conf_site', header: 'configuration site' },
            { field: 'wall_u_value', header: 'Wall U value' },
            { field: 'CO2', header: 'CO2', headerTooltip: "Air quality-CO2 index (ppm)" },
            { field: 'Edhw', header: 'Edhw', dataType: 'numeric', headerTooltip: "Energy consumption for Domestic Hot Water (kWh/m\u00B2mo)" },
            { field: 'Edhw_sol', header: 'Edhw sol', dataType: 'numeric', headerTooltip: "Energy from solar panels for DHW (kWh/m\u00B2mo)" },
            { field: 'Efan', header: 'Efan', dataType: 'numeric', headerTooltip: "Electric consumption of mechanical ventilation (kWh/m\u00B2)" },
            { field: 'Ep_nren', header: 'Ep nren', dataType: 'numeric', headerTooltip: "Non-renewable primary energy (kWh/mo)" },
            { field: 'PEd_PEc', header: 'PEd PEc', dataType: 'numeric', headerTooltip: "Primary energy demand/Primary energy consumption" },
            { field: 'RER', header: 'RER', dataType: 'numeric', headerTooltip: "Renewable Energy Ratio (kWh/m\u00B2mo)" },
            { field: 'TAC', header: 'TAC', dataType: 'numeric', headerTooltip: "Total Annual Cost (€/year)" },
            { field: 'TEc', header: 'TEc', dataType: 'numeric', headerTooltip: "Thermal Energy consumption (kWh/m\u00B2mo)" },
            { field: 'TEd', header: 'TEd', dataType: 'numeric', headerTooltip: "Thermal Energy demand (kWh/m\u00B2mo)" },
            { field: 'nZEB', header: 'nZEB', headerTooltip: "Nearly Zero Energy Building" },
            { field: 'pmb_outside_class_B', header: 'PMB outside class B', dataType: 'numeric', headerTooltip: "Thermal Comfort-Outside Class B" },
            { field: 'u_r_value_wall', header: 'UR value wall', dataType: 'numeric', headerTooltip: "Thermal Transmittance/Resistance of a wall system (W/m\u00B2K)" },
            { field: 'u_value_window', header: 'U value window', dataType: 'numeric', headerTooltip: "Thermal Transmittance of windows (W/m\u00B2K)" },
            { field: 'modeller', header: 'modeller' },
            { field: 'site', header: 'site' },
        ]

    const columnsAll = [
        { field: 'kit_id', header: 'ID', dataType: 'numeric' },
        { field: 'kit', header: 'kit' },
        { field: 'kit_name', header: 'kit name', style: { minWidth: '300px' } },
        { field: 'CO2', header: 'CO2', headerTooltip: "Air quality-CO2 index (ppm)", visibility: "hidden", display: "none" },
        { field: 'Edhw', header: 'Edhw', dataType: 'numeric', headerTooltip: "Energy consumption for Domestic Hot Water (kWh/m\u00B2mo)" },
        { field: 'Edhw_sol', header: 'Edhw sol', dataType: 'numeric', headerTooltip: "Energy from solar panels for DHW (kWh/m\u00B2mo)" },
        { field: 'Efan', header: 'Efan', dataType: 'numeric', headerTooltip: "Electric consumption of mechanical ventilation (kWh/m\u00B2)" },
        { field: 'Ep_nren', header: 'Ep nren', dataType: 'numeric', headerTooltip: "Non-renewable primary energy (kWh/mo)" },
        { field: 'PEd_PEc', header: 'PEd PEc', dataType: 'numeric', headerTooltip: "Primary energy demand/Primary energy consumption" },
        { field: 'RER', header: 'RER', dataType: 'numeric', headerTooltip: "Renewable Energy Ratio (kWh/m\u00B2mo)" },
        { field: 'TAC', header: 'TAC', dataType: 'numeric', headerTooltip: "Total Annual Cost (€/year)" },
        { field: 'TEc', header: 'TEc', dataType: 'numeric', headerTooltip: "Thermal Energy consumption (kWh/m\u00B2mo)" },
        { field: 'TEd', header: 'TEd', dataType: 'numeric', headerTooltip: "Thermal Energy demand (kWh/m\u00B2mo)" },
        { field: 'nZEB', header: 'nZEB', headerTooltip: "Nearly Zero Energy Building" },
        { field: 'pmb_outside_class_B', header: 'PMB outside class B', dataType: 'numeric', headerTooltip: "Thermal Comfort-Outside Class B" },
        { field: 'u_r_value_wall', header: 'UR value wall', dataType: 'numeric', headerTooltip: "Thermal Transmittance/Resistance of a wall system (W/m\u00B2K)" },
        { field: 'u_value_window', header: 'U value window', dataType: 'numeric', headerTooltip: "Thermal Transmittance of windows (W/m\u00B2K)" },
        { field: 'modeller', header: 'modeller' },
        { field: 'site', header: 'site' },
    ];

    const columnsHybridWall = [
        { field: 'kit_id', header: 'ID', dataType: 'numeric' },
        { field: 'kit', header: 'kit' },
        { field: 'kit_name', header: 'kit name', style: { minWidth: '300px' } },
        { field: 'operation_mode', header: 'operation mode' },
        { field: 'pv_area', header: 'PV area' },
        { field: 'shading', header: 'shading' },
        { field: 'thermal_insulation', header: 'thermal insulation' },
        { field: 'tiles', header: 'tiles' },
        { field: 'window_frame', header: 'window frame' },
        { field: 'window_glazing', header: 'window glazing' },
        { field: 'CO2', header: 'CO2', headerTooltip: "Air quality-CO2 index (ppm)" },
        { field: 'Edhw', header: 'Edhw', dataType: 'numeric', headerTooltip: "Energy consumption for Domestic Hot Water (kWh/m\u00B2mo)" },
        { field: 'Edhw_sol', header: 'Edhw sol', dataType: 'numeric', headerTooltip: "Energy from solar panels for DHW (kWh/m\u00B2mo)" },
        { field: 'Efan', header: 'Efan', dataType: 'numeric', headerTooltip: "Electric consumption of mechanical ventilation (kWh/m\u00B2)" },
        { field: 'Ep_nren', header: 'Ep nren', dataType: 'numeric', headerTooltip: "Non-renewable primary energy (kWh/mo)" },
        { field: 'PEd_PEc', header: 'PEd PEc', dataType: 'numeric', headerTooltip: "Primary energy demand/Primary energy consumption" },
        { field: 'RER', header: 'RER', dataType: 'numeric', headerTooltip: "Renewable Energy Ratio (kWh/m\u00B2mo)" },
        { field: 'TAC', header: 'TAC', dataType: 'numeric', headerTooltip: "Total Annual Cost (€/year)" },
        { field: 'TEc', header: 'TEc', dataType: 'numeric', headerTooltip: "Thermal Energy consumption (kWh/m\u00B2mo)" },
        { field: 'TEd', header: 'TEd', dataType: 'numeric', headerTooltip: "Thermal Energy demand (kWh/m\u00B2mo)" },
        { field: 'nZEB', header: 'nZEB', headerTooltip: "Nearly Zero Energy Building" },
        { field: 'pmb_outside_class_B', header: 'PMB outside class B', dataType: 'numeric', headerTooltip: "Thermal Comfort-Outside Class B" },
        { field: 'u_r_value_wall', header: 'UR value wall', dataType: 'numeric', headerTooltip: "Thermal Transmittance/Resistance of a wall system (W/m\u00B2K)" },
        { field: 'u_value_window', header: 'U value window', dataType: 'numeric', headerTooltip: "Thermal Transmittance of windows (W/m\u00B2K)" },
        { field: 'modeller', header: 'modeller' },
        { field: 'site', header: 'site' },

    ];

    const columnConEx = [
        { field: 'kit_id', header: 'ID', dataType: 'numeric' },
        { field: 'kit', header: 'kit' },
        { field: 'kit_name', header: 'kit name', style: { minWidth: '300px' } },
        { field: 'insulation_thickness', header: 'insulation thickness' },
        { field: 'pv_area', header: 'PV area' },
        { field: 'conf_site', header: 'configuration site', visibility: "hidden", display: "none" },
        { field: 'wall_u_value', header: 'Wall U value' },
        { field: 'CO2', header: 'CO2', headerTooltip: "Air quality-CO2 index (ppm)" },
        { field: 'Edhw', header: 'Edhw', dataType: 'numeric', headerTooltip: "Energy consumption for Domestic Hot Water (kWh/m\u00B2mo)" },
        { field: 'Edhw_sol', header: 'Edhw sol', dataType: 'numeric', headerTooltip: "Energy from solar panels for DHW (kWh/m\u00B2mo)" },
        { field: 'Efan', header: 'Efan', dataType: 'numeric', headerTooltip: "Electric consumption of mechanical ventilation (kWh/m\u00B2)" },
        { field: 'Ep_nren', header: 'Ep nren', dataType: 'numeric', headerTooltip: "Non-renewable primary energy (kWh/mo)" },
        { field: 'PEd_PEc', header: 'PEd PEc', dataType: 'numeric', headerTooltip: "Primary energy demand/Primary energy consumption" },
        { field: 'RER', header: 'RER', dataType: 'numeric', headerTooltip: "Renewable Energy Ratio (kWh/m\u00B2mo)" },
        { field: 'TAC', header: 'TAC', dataType: 'numeric', headerTooltip: "Total Annual Cost (€/year)" },
        { field: 'TEc', header: 'TEc', dataType: 'numeric', headerTooltip: "Thermal Energy consumption (kWh/m\u00B2mo)" },
        { field: 'TEd', header: 'TEd', dataType: 'numeric', headerTooltip: "Thermal Energy demand (kWh/m\u00B2mo)" },
        { field: 'nZEB', header: 'nZEB', headerTooltip: "Nearly Zero Energy Building" },
        { field: 'pmb_outside_class_B', header: 'PMB outside class B', dataType: 'numeric', headerTooltip: "Thermal Comfort-Outside Class B" },
        { field: 'u_r_value_wall', header: 'UR value wall', dataType: 'numeric', headerTooltip: "Thermal Transmittance/Resistance of a wall system (W/m\u00B2K)" },
        { field: 'u_value_window', header: 'U value window', dataType: 'numeric', headerTooltip: "Thermal Transmittance of windows (W/m\u00B2K)" },
        { field: 'modeller', header: 'modeller' },
        { field: 'site', header: 'site' },
    ];
    const columnSmart = [
        { field: 'kit_id', header: 'ID', dataType: 'numeric' },
        { field: 'kit', header: 'kit' },
        { field: 'kit_name', header: 'kit name', style: { minWidth: '300px' } },
        { field: 'insulation_thickness', header: 'insulation thickness' },
        { field: 'pv_area', header: 'PV area' },
        { field: 'window_glazing', header: 'window glazing ' },
        { field: 'window_frame', header: 'window frame' },
        { field: 'smartwall_type', header: 'smartwall type' },
        { field: 'Edhw', header: 'Edhw', dataType: 'numeric', headerTooltip: "Energy consumption for Domestic Hot Water (kWh/m\u00B2mo)" },
        { field: 'Edhw_sol', header: 'Edhw sol', dataType: 'numeric', headerTooltip: "Energy from solar panels for DHW (kWh/m\u00B2mo)" },
        { field: 'Ep_nren', header: 'Ep nren', dataType: 'numeric', headerTooltip: "Non-renewable primary energy (kWh/mo)" },
        { field: 'PEd_PEc', header: 'PEd PEc', dataType: 'numeric', headerTooltip: "Primary energy demand/Primary energy consumption" },
        { field: 'RER', header: 'RER', dataType: 'numeric', headerTooltip: "Renewable Energy Ratio (kWh/m\u00B2mo)" },
        { field: 'TAC', header: 'TAC', dataType: 'numeric', headerTooltip: "Total Annual Cost (€/year)" },
        { field: 'TEc', header: 'TEc', dataType: 'numeric', headerTooltip: "Thermal Energy consumption (kWh/m\u00B2mo)" },
        { field: 'TEd', header: 'TEd', dataType: 'numeric', headerTooltip: "Thermal Energy demand (kWh/m\u00B2mo)" },
        { field: 'nZEB', header: 'nZEB', headerTooltip: "Nearly Zero Energy Building" },
        { field: 'pmb_outside_class_B', header: 'PMB outside class B', dataType: 'numeric', headerTooltip: "Thermal Comfort-Outside Class B" },
        { field: 'u_r_value_wall', header: 'UR value wall', dataType: 'numeric', headerTooltip: "Thermal Transmittance/Resistance of a wall system (W/m\u00B2K)" },
        { field: 'u_value_window', header: 'U value window', dataType: 'numeric', headerTooltip: "Thermal Transmittance of windows (W/m\u00B2K)" },
        { field: 'modeller', header: 'modeller' },
        { field: 'site', header: 'site' },
    ];


    const [rows1, setRows1] = useState(20);
    const [first1, setFirst1] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedColumns, setSelectedColumns] = useState(columnInitialization);
    const [colLengthConfig, setColLengthConfig] = useState(4);
    const [selectedProduct, setSelectedProduct] = useState();
    const [tableToRender, setTableToRender] = useState("ALL");
    const [radioButton, setRadioButton] = useState("None");

    const onCustomPage1 = (event) => {
        setFirst1(event.first);
        setRows1(event.rows);
        setCurrentPage(event.page + 1);
    }

    const template1 = {
        layout: 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport',
        'PrevPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Previous</span>
                    <Ripple />
                </button>
            )
        },
        'NextPageLink': (options) => {
            return (
                <button type="button" className={options.className} onClick={options.onClick} disabled={options.disabled}>
                    <span className="p-3">Next</span>
                    <Ripple />
                </button>
            )
        },
    };

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(data);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'my_data');
        });
    }
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(module => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    }





    //***********PRIME REACT PART***********/

    function alertingOnClick(e) {

        if (e.value.kit === "HybridWall") {
            Swal.fire({
                title: 'PnU Configurations HybridWall',
                html:
                    "Operation Mode: " + e.value.operation_mode +
                    "<br>PV Area : " + e.value.pv_area +
                    "<br>Thermal Insulation: " + e.value.thermal_insulation +
                    "<br>Window Frame: " + e.value.window_frame +
                    "<br>Window Glazing: " + e.value.window_glazing +
                    "<br> Shading: " + e.value.shading +
                    "<br> Tiles: " + e.value.tiles,
                icon: 'success',
            })
        }
        if (e.value.kit === "ConExWall") {
            Swal.fire({
                title: 'PnU Configurations ConExWall',
                html:
                    "<br>PV Area : " + e.value.pv_area +
                    "<br>Insulation Thickness: " + e.value.insulation_thickness +
                    "<br>Wall U Value: " + e.value.wall_u_value,
                icon: 'success',
            })
        }
        if (e.value.kit === "SmartWall") {
            Swal.fire({
                title: 'PnU Configurations SmartWall',
                html:
                    "<br>PV Area : " + e.value.pv_area +
                    "<br>Insulation Thickness: " + e.value.insulation_thickness +
                    "<br>Window Frame: " + e.value.window_frame +
                    "<br>SmartWall Type: " + e.value.smartwall_type +
                    "<br>Window Glazing: " + e.value.window_glazing,
                icon: 'success',
            })
        }
        if (radioButton === "ALL") {
            const text = e.value.kit_name;
            const splitText = text.split("_");
            if (e.value.kit === "HybridWall") {
                Swal.fire({
                    title: 'PnU Configurations HybridWall',
                    html:
                        "Operation Mode: " + splitText[0] +
                        "<br>PV Area : " + splitText[1] +
                        "<br>Thermal Insulation: " + splitText[2] +
                        "<br>Window Frame: " + splitText[3] +
                        "<br>Window Glazing: " + splitText[4] +
                        "<br> Shading: " + splitText[5] +
                        "<br> Tiles: " + splitText[6],
                    icon: 'success',
                })
            }
            if (e.value.kit === "ConExWall") {
                Swal.fire({
                    title: 'PnU Configurations ConExWall',
                    html:
                        "<br>PV Area : " + splitText[2] +
                        "<br>Wall U Value: " + splitText[1],
                    icon: 'success',
                })
            }
            if (e.value.kit === "SmartWall") {
                Swal.fire({
                    title: 'PnU Configurations SmartWall',
                    html:
                        "<br>PV Area : " + splitText[4] +
                        "<br>Insulation Thickness: " + splitText[1] +
                        "<br>Window Frame: " + splitText[2] +
                        "<br>SmartWall Type: " + splitText[0] +
                        "<br>Window Glazing: " + splitText[3],
                    icon: 'success',
                })
            }
        }

    }


    let pnuData = [];
    const { keycloak, initialized } = useKeycloak();
    const navigate = useNavigate();
    const date = new Date();

    const [gridRef, setGridRef] = useState(null);

    const downloadBlob = (blob, fileName = `MODEST_${date.getDate()}_${date.getMonth() + 1}_${date.getFullYear()}.csv`) => {
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.position = 'absolute';
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const exportCSV = () => {
        const columns = gridRef.current.visibleColumns;
        const header = columns.map((c) => c.name).join(SEPARATOR);
        const rows = gridRef.current.data.map((data) => columns.map((c) => data[c.id]).join(SEPARATOR));
        const contents = [header].concat(rows).join('\n');
        const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
        downloadBlob(blob);
    };



    //****************************************** API+ Fetch AND COMMUNICATION For DIFFERENT ENDPOINTS **************************************/
    //Fetch Function to retrieve ALL from API
    const renderHeader1 = () => {
        return (
            <div className="flex justify-content-between">
                <MultiSelect display="chip" value={selectedColumns} options={columnInitialization} optionLabel="header" onChange={onColumnToggle} style={{ width: '20em' }} />
                <Button tooltip="Download Data" style={{ margin: "2px 2px 1px 10px" }} type="button" icon="pi pi-file-excel" onClick={exportExcel} className="p-button-success mr-2" />
            </div>
        )
    }



    const onColumnToggle = (event) => {
        let selectedColumnsforToggle = event.value;
        let orderedSelectedColumns = columnInitialization.filter(col => selectedColumnsforToggle.some(sCol => sCol.field === col.field));
        setSelectedColumns(orderedSelectedColumns);
    }

    const header1 = renderHeader1();


    const columnComponents = selectedColumns.map(col => {
        return <Column headerTooltip={col.headerTooltip} style={col.style} headerTooltipOptions={{ position: 'top' }} key={col.field} field={col.field} header={col.header} dataType={col.dataType} filter filterElement={col.value} sortable />
    });

    const headerGroup =
        (
            <ColumnGroup>
                <Row>
                    <Column header="General Kit Information" colSpan={3} />
                    <Column header="PnU Configurations" colSpan={colLengthConfig} />
                    <Column header="KPI Fields" colSpan={16} />
                </Row>
                <Row>
                    {columnComponents}
                </Row>
            </ColumnGroup>
        )
    const headerGroupAll =
        (
            <ColumnGroup>
                <Row>
                    <Column header="General Kit Information" colSpan={3} />
                    <Column header="KPI Fields" colSpan={16} />
                </Row>
                <Row>
                    {columnComponents}
                </Row>
            </ColumnGroup>
        )


    //FETCH ALL -- NO INPUT IN DROPDOWNS
    // async function fetchAll() {
    //     setHideArr(false);
    //     setWhatWeFetch("ALL")
    //     setWhichKPI(0);
    //     setCurrentKIT("SmartWall");
    //     setData([]);
    //     try {
    //         const fetched = await fetch(`/pnus`);
    //         const pnu = await fetched.json();

    //         setColLengthConfig(0);
    //         if (pnu.length > 0) {

    //             for (let i = 0; i < pnu.length; i++) {
    //                 let Efan;
    //                 if (typeof pnu[i]['kpi'].Efan === 'string') {
    //                     Efan = pnu[i]['kpi'].Efan;
    //                 }
    //                 else {
    //                     Efan = Number(pnu[i]['kpi'].TEd.toFixed(2));
    //                 }
    //                 pnuData.push(new PnUObjNew(pnu[i].kit, pnu[i].kit_config_id, pnu[i].kit_config_name, pnu[i]['kpi'].CO2, Number(pnu[i]['kpi'].Edhw.toFixed(2)), Number(pnu[i]['kpi'].Edhw_sol.toFixed(2)), Efan,
    //                     Number(pnu[i]['kpi'].Ep_nren.toFixed(2)), Number(pnu[i]['kpi'].PEd_PEc.toFixed(2)), Number(pnu[i]['kpi'].RER.toFixed(2)), Number(pnu[i]['kpi'].TAC.toFixed(2)), Number(pnu[i]['kpi'].TEc.toFixed(2)), Number(pnu[i]['kpi'].TEd.toFixed(2)), pnu[i]['kpi'].nZEB.toString(), Number(pnu[i]['kpi'].pmv_outside_b_class.toFixed(2)), Number(pnu[i]['kpi'].u_r_value_wall.toFixed(2)), Number(pnu[i]['kpi'].u_value_window.toFixed(2)), pnu[i].modeller, pnu[i].site));
    //             }
    //             setData([...pnuData]);

    //         }
    //     } catch (er) {
    //         console.error("Failed: ", er)
    //     }

    // }

    async function fetchAll() {
        setHideArr(false);
        setWhatWeFetch("ALL");
        setWhichKPI(0);
        setCurrentKIT("SmartWall");
        setCurrentKIT2("SmartWall");
        setData([]);

        // Initialize filtered results
        const initialFilteredResults = {
            SmartWall: [],
            HybridWall: [],
            ConExWall: []
        };

        try {
            const fetched = await fetch(`/pnus`);
            const pnu = await fetched.json();

            setColLengthConfig(0);
            if (pnu.length > 0) {
                const pnuData = []; // Initialize this array to hold fetched data

                for (let i = 0; i < pnu.length; i++) {
                    let Efan;
                    if (typeof pnu[i]['kpi'].Efan === 'string') {
                        Efan = pnu[i]['kpi'].Efan;
                    } else {
                        Efan = Number(pnu[i]['kpi'].TEd.toFixed(2));
                    }

                    const newObj = new PnUObjNew(
                        pnu[i].kit,
                        pnu[i].kit_config_id,
                        pnu[i].kit_config_name,
                        pnu[i]['kpi'].CO2,
                        Number(pnu[i]['kpi'].Edhw.toFixed(2)),
                        Number(pnu[i]['kpi'].Edhw_sol.toFixed(2)),
                        Efan,
                        Number(pnu[i]['kpi'].Ep_nren.toFixed(2)),
                        Number(pnu[i]['kpi'].PEd_PEc.toFixed(2)),
                        Number(pnu[i]['kpi'].RER.toFixed(2)),
                        Number(pnu[i]['kpi'].TAC.toFixed(2)),
                        Number(pnu[i]['kpi'].TEc.toFixed(2)),
                        Number(pnu[i]['kpi'].TEd.toFixed(2)),
                        pnu[i]['kpi'].nZEB.toString(),
                        Number(pnu[i]['kpi'].pmv_outside_b_class.toFixed(2)),
                        Number(pnu[i]['kpi'].u_r_value_wall.toFixed(2)),
                        Number(pnu[i]['kpi'].u_value_window.toFixed(2)),
                        pnu[i].modeller,
                        pnu[i].site
                    );

                    pnuData.push(newObj);

                    // Populate initialFilteredResults based on kit type
                    if (pnu[i].kit in initialFilteredResults) {
                        initialFilteredResults[pnu[i].kit].push(newObj);
                    }
                }

                setData([...pnuData]);
                setFilteredResults(initialFilteredResults); // Set the filtered results state

            }
        } catch (er) {
            console.error("Failed: ", er);
        }
    }



    //FETCH By KIT ONLY
    async function fetchByKit(kit) {
        setData([]);
        setCurrentKIT(kit)
        setCurrentKIT2(kit)
        setWhatWeFetch("KIT")
        const fetched = await fetch(`/pnus?kit=${kit}`);
        const pnu = await fetched.json();
        if (kit === "HybridWall") {
            setCurrentKIT("HybridWall")
            setWhichKPI(1)
            setHideArr(true)
            setColLengthConfig(7);
            setSelectedColumns(columnsHybridWall)
            console.log("HEllo HybridWall")
            if (pnu.length > 0) {
                for (let i = 0; i < pnu.length; i++) {
                    pnuData.push(new PnUObjHybrid(pnu[i].kit_config_id, pnu[i].kit, pnu[i].kit_config_name, pnu[i]['config'].operation_mode, pnu[i]['config'].pv_area, pnu[i]['config'].shading, pnu[i]['config'].thermal_insulation, pnu[i]['config'].tiles, pnu[i]['config'].window_frame, pnu[i]['config'].window_glazing, pnu[i]['kpi'].CO2, Number(pnu[i]['kpi'].Edhw.toFixed(2)), Number(pnu[i]['kpi'].Edhw_sol.toFixed(2)), Number(pnu[i]['kpi'].Efan.toFixed(2)),
                        Number(pnu[i]['kpi'].Ep_nren.toFixed(2)), Number(pnu[i]['kpi'].PEd_PEc.toFixed(2)), Number(pnu[i]['kpi'].RER.toFixed(2)), Number(pnu[i]['kpi'].TAC.toFixed(2)), Number(pnu[i]['kpi'].TEc.toFixed(2)), Number(pnu[i]['kpi'].TEd.toFixed(2)), pnu[i]['kpi'].nZEB, Number(pnu[i]['kpi'].pmv_outside_b_class.toFixed(2)), Number(pnu[i]['kpi'].u_r_value_wall.toFixed(2)), Number(pnu[i]['kpi'].u_value_window.toFixed(2)), pnu[i].modeller, pnu[i].site));
                }
                setData([...pnuData]);
            }
        }
        if (kit === "ConExWall") {
            setCurrentKIT("ConExWall")
            setCurrentKIT2("ConExWall")
            setWhichKPI(2)
            setHideArr(true)
            setColLengthConfig(4);
            setSelectedColumns(columnConEx)

            if (pnu.length > 0) {
                for (let i = 0; i < pnu.length; i++) {
                    pnuData.push(new PnUObjConEx(pnu[i].kit_config_id, pnu[i].kit, pnu[i].kit_config_name, pnu[i]['config'].insulation_thickness, pnu[i]['config'].pv_area, pnu[i]['config'].site, pnu[i]['config'].wall_u_value, pnu[i]['kpi'].CO2, Number(pnu[i]['kpi'].Edhw.toFixed(2)), Number(pnu[i]['kpi'].Edhw_sol.toFixed(2)), Number(pnu[i]['kpi'].Efan.toFixed(2)),
                        Number(pnu[i]['kpi'].Ep_nren.toFixed(2)), Number(pnu[i]['kpi'].PEd_PEc.toFixed(2)), Number(pnu[i]['kpi'].RER.toFixed(2)), Number(pnu[i]['kpi'].TAC.toFixed(2)), Number(pnu[i]['kpi'].TEc.toFixed(2)), Number(pnu[i]['kpi'].TEd.toFixed(2)), pnu[i]['kpi'].nZEB, Number(pnu[i]['kpi'].pmv_outside_b_class.toFixed(2)), Number(pnu[i]['kpi'].u_r_value_wall.toFixed(2)), Number(pnu[i]['kpi'].u_value_window.toFixed(2)), pnu[i].modeller, pnu[i].site));
                }
                setData([...pnuData]);
            }
        }
        if (kit === "SmartWall") {
            setCurrentKIT("SmartWall")
            setCurrentKIT2("SmartWall")
            setWhichKPI(0)
            setHideArr(true)
            setColLengthConfig(5);
            setSelectedColumns(columnSmart)

            if (pnu.length > 0) {
                for (let i = 0; i < pnu.length; i++) {
                    pnuData.push(new PnUObjSmart(pnu[i].kit_config_id, pnu[i].kit, pnu[i].kit_config_name, pnu[i]['config'].insulation_thickness, pnu[i]['config'].pv_area, pnu[i]['config'].window_glazing, pnu[i]['config'].window_frame, pnu[i]['config'].smartwall_type, Number(pnu[i]['kpi'].Edhw.toFixed(2)), Number(pnu[i]['kpi'].Edhw_sol.toFixed(2)),
                        Number(pnu[i]['kpi'].Ep_nren.toFixed(2)), Number(pnu[i]['kpi'].PEd_PEc.toFixed(2)), Number(pnu[i]['kpi'].RER.toFixed(2)), Number(pnu[i]['kpi'].TAC.toFixed(2)), Number(pnu[i]['kpi'].TEc.toFixed(2)), Number(pnu[i]['kpi'].TEd.toFixed(2)), pnu[i]['kpi'].nZEB, Number(pnu[i]['kpi'].pmv_outside_b_class.toFixed(2)), Number(pnu[i]['kpi'].u_r_value_wall.toFixed(2)), Number(pnu[i]['kpi'].u_value_window.toFixed(2)), pnu[i].modeller, pnu[i].site));
                }
                setData([...pnuData]);
            }

        }
    }

    async function fetchBySite(site) {
        setWhatWeFetch("Site")
        setData([]);
        const fetched = await fetch(`/pnus?site=${site}`);
        const pnu = await fetched.json();
        if (site === "TERRASSA") {
            setColLengthConfig(7);
            setCurrentKIT("HybridWall")
            setCurrentKIT2("HybridWall")
            setWhichKPI(1)
            setHideArr(true)
            setSelectedColumns(columnsHybridWall)
            if (pnu.length > 0) {
                for (let i = 0; i < pnu.length; i++) {
                    pnuData.push(new PnUObjHybrid(pnu[i].kit_config_id, pnu[i].kit, pnu[i].kit_config_name, pnu[i]['config'].operation_mode, pnu[i]['config'].pv_area, pnu[i]['config'].shading, pnu[i]['config'].thermal_insulation, pnu[i]['config'].tiles, pnu[i]['config'].window_frame, pnu[i]['config'].window_glazing, pnu[i]['kpi'].CO2, Number(pnu[i]['kpi'].Edhw.toFixed(2)), Number(pnu[i]['kpi'].Edhw_sol.toFixed(2)), Number(pnu[i]['kpi'].Efan.toFixed(2)),
                        Number(pnu[i]['kpi'].Ep_nren.toFixed(2)), Number(pnu[i]['kpi'].PEd_PEc.toFixed(2)), Number(pnu[i]['kpi'].RER.toFixed(2)), Number(pnu[i]['kpi'].TAC.toFixed(2)), Number(pnu[i]['kpi'].TEc.toFixed(2)), Number(pnu[i]['kpi'].TEd.toFixed(2)), pnu[i]['kpi'].nZEB, Number(pnu[i]['kpi'].pmv_outside_b_class.toFixed(2)), Number(pnu[i]['kpi'].u_r_value_wall.toFixed(2)), Number(pnu[i]['kpi'].u_value_window.toFixed(2)), pnu[i].modeller, pnu[i].site));
                }
                setData([...pnuData]);
            }
        }
        if (site === "KASAVA") {
            setColLengthConfig(4);
            setCurrentKIT("ConExWall")
            setCurrentKIT2("ConExWall")
            setWhichKPI(2)
            setHideArr(true)
            setSelectedColumns(columnConEx)
            if (pnu.length > 0) {
                for (let i = 0; i < pnu.length; i++) {
                    pnuData.push(new PnUObjConEx(pnu[i].kit_config_id, pnu[i].kit, pnu[i].kit_config_name, pnu[i]['config'].insulation_thickness, pnu[i]['config'].pv_area, pnu[i]['config'].site, pnu[i]['config'].wall_u_value, pnu[i]['kpi'].CO2, Number(pnu[i]['kpi'].Edhw.toFixed(2)), Number(pnu[i]['kpi'].Edhw_sol.toFixed(2)), Number(pnu[i]['kpi'].Efan.toFixed(2)),
                        Number(pnu[i]['kpi'].Ep_nren.toFixed(2)), Number(pnu[i]['kpi'].PEd_PEc.toFixed(2)), Number(pnu[i]['kpi'].RER.toFixed(2)), Number(pnu[i]['kpi'].TAC.toFixed(2)), Number(pnu[i]['kpi'].TEc.toFixed(2)), Number(pnu[i]['kpi'].TEd.toFixed(2)), pnu[i]['kpi'].nZEB, Number(pnu[i]['kpi'].pmv_outside_b_class.toFixed(2)), Number(pnu[i]['kpi'].u_r_value_wall.toFixed(2)), Number(pnu[i]['kpi'].u_value_window.toFixed(2)), pnu[i].modeller, pnu[i].site));
                }
                setData([...pnuData]);
            }
        }
        if (site === "Berlin") {
            setColLengthConfig(5);
            setWhichKPI(0)
            setCurrentKIT("SmartWall")
            setCurrentKIT2("SmartWall")
            setHideArr(true)
            setSelectedColumns(columnSmart)

            if (pnu.length > 0) {
                for (let i = 0; i < pnu.length; i++) {
                    pnuData.push(new PnUObjSmart(pnu[i].kit_config_id, pnu[i].kit, pnu[i].kit_config_name, pnu[i]['config'].insulation_thickness, pnu[i]['config'].pv_area, pnu[i]['config'].window_glazing, pnu[i]['config'].window_frame, pnu[i]['config'].smartwall_type, Number(pnu[i]['kpi'].Edhw.toFixed(2)), Number(pnu[i]['kpi'].Edhw_sol.toFixed(2)),
                        Number(pnu[i]['kpi'].Ep_nren.toFixed(2)), Number(pnu[i]['kpi'].PEd_PEc.toFixed(2)), Number(pnu[i]['kpi'].RER.toFixed(2)), Number(pnu[i]['kpi'].TAC.toFixed(2)), Number(pnu[i]['kpi'].TEc.toFixed(2)), Number(pnu[i]['kpi'].TEd.toFixed(2)), pnu[i]['kpi'].nZEB, Number(pnu[i]['kpi'].pmv_outside_b_class.toFixed(2)), Number(pnu[i]['kpi'].u_r_value_wall.toFixed(2)), Number(pnu[i]['kpi'].u_value_window.toFixed(2)), pnu[i].modeller, pnu[i].site));
                }
                setData([...pnuData]);
            }
        }
        if (site === "VVV") {
            setColLengthConfig(5);
            setWhichKPI(0)
            setCurrentKIT("SmartWall")
            setCurrentKIT2("SmartWall")
            setHideArr(true)
            setSelectedColumns(columnSmart)

            if (pnu.length > 0) {
                for (let i = 0; i < pnu.length; i++) {
                    pnuData.push(new PnUObjSmart(pnu[i].kit_config_id, pnu[i].kit, pnu[i].kit_config_name, pnu[i]['config'].insulation_thickness, pnu[i]['config'].pv_area, pnu[i]['config'].window_glazing, pnu[i]['config'].window_frame, pnu[i]['config'].smartwall_type, Number(pnu[i]['kpi'].Edhw.toFixed(2)), Number(pnu[i]['kpi'].Edhw_sol.toFixed(2)),
                        Number(pnu[i]['kpi'].Ep_nren.toFixed(2)), Number(pnu[i]['kpi'].PEd_PEc.toFixed(2)), Number(pnu[i]['kpi'].RER.toFixed(2)), Number(pnu[i]['kpi'].TAC.toFixed(2)), Number(pnu[i]['kpi'].TEc.toFixed(2)), Number(pnu[i]['kpi'].TEd.toFixed(2)), pnu[i]['kpi'].nZEB, Number(pnu[i]['kpi'].pmv_outside_b_class.toFixed(2)), Number(pnu[i]['kpi'].u_r_value_wall.toFixed(2)), Number(pnu[i]['kpi'].u_value_window.toFixed(2)), pnu[i].modeller, pnu[i].site));
                }
                setData([...pnuData]);
            }
        }
        // if (data.length === 0) {
        //     console.log("Is empty");
        // }
    }
    //SELECTORS FUNCTIONS
    const [selectedSite, setSelectedSite] = useState("None");
    const [selectedKit, setSelectedKit] = useState("None");

    function radioButtonChecked(e) {
        setRadioButton(e.target.value);
        if (e.target.value === "ALL") {
            setSelectedKit("None")
            setSelectedSite("None")
        }
        if (e.target.value === "KIT") {
            setSelectedSite("None")
        }
        if (e.target.value === "SITE") {
            setSelectedKit("None")
        }
    }

    function selectSite(e) {
        setSelectedSite(e.target.value);
    }
    function selectKit(e) {
        setSelectedKit(e.target.value);
    }


    function fetchGeneral() {
        setShowModels(true)
        clearAll()
        setCardList([]);
        if (selectedSite === "None" && selectedKit === "None") {
            fetchAll();
            setSelectedColumns(columnsAll);
            setTableToRender("ALL");
        }
        if (selectedSite !== "None") {
            fetchBySite(selectedSite);
            setTableToRender("KIT");
        }
        if (selectedKit !== "None") {
            fetchByKit(selectedKit);
            setTableToRender("KIT");
        }

    }


    useEffect(() => {
        if (window.innerWidth < 1300)
            setRows1(10)
        if (window.innerWidth >= 1300)
            setRows1(20)

    }, [rows1])



    //******* Manage the Height and Rows of the Table based on the Screen Size (Who did it Bi@@@h!) ********/
    useEffect(() => {//FUCKING IMPORTANT
        setData(data)
        setFilteredData(data)
    }, [data])


    //*********************************SMART WALL STAFF**********************************/
    const [isActive, setIsActive] = useState(false);
    const [isActive2, setIsActive2] = useState(false);
    const [isActive3, setIsActive3] = useState(false);
    const [isActive4, setIsActive4] = useState(false);
    const [isActive5, setIsActive5] = useState(false);
    const [isActive6, setIsActive6] = useState(false);
    const [isActiveCon, setIsActiveCon] = useState(false);
    const [isActiveCon2, setIsActiveCon2] = useState(false);
    const [isActiveCon3, setIsActiveCon3] = useState(false);
    const [isActiveCon4, setIsActiveCon4] = useState(false);
    const [isActiveCon5, setIsActiveCon5] = useState(false);
    const [isActiveCon6, setIsActiveCon6] = useState(false);
    const [isActiveWin, setIsActiveWin] = useState(false);
    const [isActiveWin1, setIsActiveWin1] = useState(false);
    const [isActiveWin2, setIsActiveWin2] = useState(false);
    const [isActiveWin3, setIsActiveWin3] = useState(false);
    const [isActiveWin4, setIsActiveWin4] = useState(false);
    const [isActiveWin5, setIsActiveWin5] = useState(false);
    const [isActiveWin6, setIsActiveWin6] = useState(false);
    const [isActiveWin7, setIsActiveWin7] = useState(false);
    const [isActiveWin8, setIsActiveWin8] = useState(false);
    const [isActiveWin9, setIsActiveWin9] = useState(false);
    const [isActiveWin10, setIsActiveWin10] = useState(false);
    const [radioLabel, setRadioLabel] = useState("Click on the Model")
    const [radioOption1, setRadioOption1] = useState("Option1")
    const [radioOption2, setRadioOption2] = useState("Option2")
    const [radioOption3, setRadioOption3] = useState("Option3")
    const [radioOption4, setRadioOption4] = useState("Option4")
    const [radioOption5, setRadioOption5] = useState("Option5")
    const [numberOfOptions, setnumberofOptions] = useState(0);
    const [radioChoise, setRadioChoise] = useState("");
    const [hide, setHide] = useState(false);

    function showMeTheClass(e) {
        setnumberofOptions(0);
        console.log(e.currentTarget.className);
        if (e.currentTarget.className === "shape cuboid-1 cub-1") {
            setIsActive(current => !current);
            setIsActive3(false)
            setIsActive4(false)
            setIsActive5(false)
            setIsActive6(false)
            setRadioLabel("Insulation Thickness")
            setRadioOption1("100mm")
            setRadioOption2("160mm")
            setRadioOption3("240mm")
            setnumberofOptions(3)
        }
        if (e.currentTarget.className === "shape cuboid-4 cub-4") {
            setIsActive4(current => !current);
            setIsActive(false)
            setIsActive3(false)
            setIsActive5(false)
            setIsActive6(false)
            setRadioLabel("Window Glass")
            setRadioOption1("Double")
            setRadioOption2("Triple")
            setnumberofOptions(2)

        }
        if (e.currentTarget.className === "shape cuboid-5 cub-5") {
            setIsActive5(current => !current);
            setIsActive(false)
            setIsActive3(false)
            setIsActive4(false)
            setIsActive6(false)
            setRadioLabel("Window Frame (S)")
            setRadioOption1("PVC")
            setRadioOption2("Aluminium (ALUM)")
            setnumberofOptions(2)
        }
        if (e.currentTarget.className === "shape cuboid-6 cub-6") {
            setIsActive6(current => !current);
            setIsActive(false)
            setIsActive3(false)
            setIsActive5(false)
            setIsActive4(false)
            setRadioLabel("PV Area (S)")
            setRadioOption1("0m\u00B2")
            setRadioOption2("10m\u00B2")
            setRadioOption3("20m\u00B2")
            setRadioOption4("30m\u00B2")
            setRadioOption5("40m\u00B2")
            setnumberofOptions(5)
        }
        //__________HybridWall Staff______________
        if (e.currentTarget.className === 'shapeHyb cuboid-5 cub-5Hyb' ||
            e.currentTarget.className === 'shapeHyb cuboid-6 cub-6Hyb' ||
            e.currentTarget.className === 'shapeHyb cuboid-7 cub-7Hyb' ||
            e.currentTarget.className === 'shapeHyb cuboid-8 cub-8Hyb' ||
            e.currentTarget.className === 'shapeHyb cuboid-9 cub-9Hyb') {
            setIsActiveWin1(false);
            setIsActiveWin2(false);
            setIsActiveWin3(false);
            setIsActiveWin4(false);
            setIsActiveWin5(current => !current);
            setIsActiveWin6(current => !current);
            setIsActiveWin7(current => !current);
            setIsActiveWin8(current => !current);
            setIsActiveWin9(current => !current);
            setIsActiveWin10(false);
            setRadioLabel("Window Frame (H)")
            setRadioOption1("Aluminium (wAlu68)")
            setRadioOption2("Aluminium (wAlu78)")

            setnumberofOptions(2)

        }
        if (e.currentTarget.className === 'shapeHyb cuboid-3 cub-3Hyb') {
            setIsActiveWin1(current => !current);
            setIsActiveWin2(false);
            setIsActiveWin2(false);
            setIsActiveWin3(current => !current);
            setIsActiveWin4(false);
            setIsActiveWin5(false);
            setIsActiveWin6(false);
            setIsActiveWin7(false);
            setIsActiveWin(false);
            setIsActiveWin8(false);
            setIsActiveWin9(false);
            setIsActiveWin10(false);
            setRadioLabel("Tiles")
            setRadioOption1("Aluminium (Al)")
            setRadioOption2("Steel 304 (S304)")
            setRadioOption3("Steel 316 (S316)")
            setRadioOption4("HPL")
            setnumberofOptions(4)
        }
        if (e.currentTarget.className === 'shapeHyb cuboid-2 cub-2Hyb') {
            setIsActiveWin1(false);
            setIsActiveWin2(current => !current);
            setIsActiveWin3(false);
            setIsActiveWin4(false);
            setIsActiveWin5(false);
            setIsActiveWin6(false);
            setIsActiveWin7(false);
            setIsActiveWin(false);
            setIsActiveWin8(false);
            setIsActiveWin9(false);
            setIsActiveWin10(false);
            setRadioLabel("Thermal Insulation")
            setRadioOption1("Therm ECO50 (ECO50)")
            setRadioOption2("Therm100 (ECO100)")
            setnumberofOptions(2)
        }
        if (e.currentTarget.className === 'shapeHyb cuboid-10 cub-10Hyb') {
            setIsActiveWin1(false);
            setIsActiveWin2(false);
            setIsActiveWin3(false);
            setIsActiveWin4(false);
            setIsActiveWin5(false);
            setIsActiveWin6(false);
            setIsActiveWin7(false);
            setIsActiveWin(false);
            setIsActiveWin8(false);
            setIsActiveWin9(false);
            setIsActiveWin10(current => !current);
            setRadioLabel("Window Glazing")
            setRadioOption1("Glass 2G_44dB (2G44);")
            setRadioOption2("Glass 2G_51dB (2G51)")
            setnumberofOptions(2)
        }
        if (e.currentTarget.className === 'shapeHyb cuboid-4 cub-4Hyb') {
            setIsActiveWin1(false);
            setIsActiveWin2(false);
            setIsActiveWin3(false);
            setIsActiveWin4(current => !current);
            setIsActiveWin5(false);
            setIsActiveWin6(false);
            setIsActiveWin7(false);
            setIsActiveWin(false);
            setIsActiveWin8(false);
            setIsActiveWin9(false);
            setIsActiveWin10(false);
            setRadioLabel("PV Area (H)")
            setRadioOption1("NO PV (NO)")
            setRadioOption2("East facade (EF)")
            setRadioOption3("East Roof (ER)")
            setRadioOption4("West Roof (WR)")
            setRadioOption5("All")
            setnumberofOptions(5)
        }

        //..................ConExWall Staff............
        if (e.currentTarget.className === "shapeCon cuboid-2 cub-2Con") {
            setIsActiveCon6(false);
            setIsActiveCon2(current => !current)
            setRadioLabel("Wall U Value")
            setRadioOption1("0.15")
            setRadioOption2("0.17")
            setRadioOption3("0.19")
            setnumberofOptions(3)
        }
        if (e.currentTarget.className === "shapeCon cuboid-6 cub-6Con") {
            setIsActiveCon6(current => !current);
            setIsActiveCon2(false)
            setIsActiveCon(false)
            setIsActiveCon3(false)
            setIsActiveCon5(false)
            setIsActiveCon4(false)
            setRadioLabel("PV Area (C)")
            setRadioOption1("24m\u00B2")
            setRadioOption2("30m\u00B2")
            setRadioOption3("36m\u00B2")
            setRadioOption4("42m\u00B2")
            setRadioOption5("48m\u00B2")
            setnumberofOptions(5)
        }

    }

    useEffect(() => {
        if (radioLabel === "Click on the Model") {
            setHide(false);
        }
        else {
            setHide(true);
        }
    }, [radioLabel])




    function handleRadioChange(e) {
        setRadioChoise(e.target.value);
    }

    useEffect(() => {

    }, [radioChoise])
    const [cardList, setCardList] = useState([]);
    let newCardList = [];
    const [weSubmit, setWeSubmit] = useState(false)
    const removeCard = (infocard_text) => {
        setWeSubmit(current => !current)
        console.log("Removing card with infocard_text:", infocard_text);

        // updating the card list to remove the card by its infocard_text
        setCardList(oldList => oldList.filter(card => card.props.infocard_text !== infocard_text));


        // updating the radio choices array to remove the corresponding choice (if relevant)
        setRadioChoicesArray(oldChoices => oldChoices.filter(choice => !infocard_text.includes(choice)));
        console.log("Updated 1", radioChoicesArray)
        if (whatWeFetch === "ALL") {
            // removingf the corresponding filter for the current kit based on the removed cards infocard_text
            setFilteredResults(prevFilteredResults => {
                const updatedFilteredResults = { ...prevFilteredResults };

                
                Object.keys(updatedFilteredResults).forEach(kitType => {
                    // current kit filtered results
                    const currentKitFilteredResults = updatedFilteredResults[kitType] || [];

                    // removing kits whose kit_name matches the removed infocard_text
                    const newFilteredResults = currentKitFilteredResults.filter(kit => {

                        return !infocard_text.includes(kit.kit_name);
                    });

                    // updating the filtered results for the current kit
                    updatedFilteredResults[kitType] = newFilteredResults;
                });

                // updated filtered results
                return updatedFilteredResults;
            });
        }
    };


    ////This is the part where the Window is used
    //TODO -- Check whether the List of window Info (i.e. thickness of insulation is unique)
    const [uniquePnU, setUniquePnU] = useState([]);
    const [currentKIT, setCurrentKIT] = useState("SmartWall");
    const [currentKIT2, setCurrentKIT2] = useState("SmartWall");


    const [filteredResults, setFilteredResults] = useState({
        SmartWall: [],
        HybridWall: [],
        ConExWall: []
    });
    const [radioChoicesArray, setRadioChoicesArray] = useState([]);
    //pre-processing radio coices for filtering
    const processRadioChoice = (choice) => {
        if (choice.includes('(') && choice.includes(')')) {
            return choice; // Keep if it has parentheses
        } else if (choice.includes('m')) {
            return choice.split('m')[0]; // Keep everything before 'm'
        } else if (choice.includes('.')) {
            // Handle cases like '0.19' and turn them into '019'
            return choice.replace(/\./g, '').padStart(3, '0');
        }
        return choice; // choice as is if none of the conditions met
    };



    //On submit For 3D model
    const submit_label_choise = () => {
        setWeSubmit(current => !current)
        console.log(radioLabel);
        const processedChoice = processRadioChoice(radioChoise);

        // updating card list and radio choices array
        setCardList((oldList) => {
            const newCardText = radioLabel + ": " + radioChoise;
            const existingIndex = oldList.findIndex(card => card.props.infocard_text.startsWith(radioLabel));

            if (existingIndex !== -1) {
                // updating existing card
                const updatedList = [...oldList];
                updatedList[existingIndex] = <InfoPnUCard infocard_text={newCardText} currentKit={currentKIT2} removeCard={() => removeCard(newCardText)} />;
                return updatedList;
            } else {
                // updating new card
                return [...oldList, <InfoPnUCard infocard_text={newCardText} currentKit={currentKIT2} removeCard={() => removeCard(newCardText)} />];
            }
        });

        // updating the radioChoicesArray
        setRadioChoicesArray((prevChoices) => {
            if (!prevChoices.includes(processedChoice)) {
                return [...prevChoices, processedChoice]; // updating IF not already present
            }
            return prevChoices; 
        });

        console.log("Current processed choice:", processedChoice);
        console.log("Updated radio choices array 3:", radioChoicesArray);
        clearAll(); 
    };

    function clearAll() {
        setIsActive(false)
        setIsActive2(false)
        setIsActive3(false)
        setIsActive4(false)
        setIsActive5(false)
        setIsActive6(false)
        setIsActiveCon(false)
        setIsActiveCon5(false)
        setIsActiveCon4(false)
        setIsActiveCon3(false)
        setIsActiveCon2(false)
        setIsActiveCon6(false)
        setIsActiveWin1(false)
        setIsActiveWin2(false)
        setIsActiveWin3(false)
        setIsActiveWin5(false)
        setIsActiveWin4(false)
        setIsActiveWin6(false)
        setIsActiveWin7(false)
        setIsActiveWin8(false)
        setIsActiveWin9(false)
        setIsActiveWin10(false)
        setRadioLabel("Click on the Model")
        setnumberofOptions(0)
    }
    const [whichKPI, setWhichKPI] = useState(0);
    const [hover, setHover] = useState(false);
    const [hoverR, setHoverR] = useState(false);

    //keping only the inner text of choice of interest
    const preprocessChoices = (choices) => {
        console.log(choices)
        return choices.map(choice => {
            const match = choice.match(/\(([^)]+)\)/); // Match content inside parentheses
            if (match) {
                return match[1];
            } else if (choice.includes('m')) {
                return choice.split('m')[0]; // Keep everything before 'm'
            }
            else if (choice.includes('.')) {
                // Handle cases like '0.19' and turn them into '019'
                return choice.replace(/\./g, '').padStart(3, '0');
            }
            return choice; //choice as is==> if none of the conditions met
        });
    };

    useEffect(() => {
        // mapping of current choices based on the cardList
        const currentChoices = cardList.map(card => {
            //  text from the card  format "Label: Choice"
            const text = card.props.infocard_text;
            const [label, choice] = text.split(": ").map(item => item.trim()); // split label and choice
            return { label, choice }; //object with label and choice
        });

        // Updating radioChoicesArray based on current choices
        setRadioChoicesArray(oldChoices => {
            //a new array to store updated choices
            const updatedChoices = currentChoices.map(item => item.choice);

            // only choices corresponding to existing cards are kept
            const newChoices = updatedChoices.slice(-cardList.length);

            console.log("Old Choices:", oldChoices);
            console.log("New Choices:", newChoices);

            // check step IF the new choices are different from the old ones
            const choicesAreDifferent = newChoices.length !== oldChoices.length ||
                !newChoices.every((val, index) => val === oldChoices[index]);

            //  new choices if they are different, otherwise return old choices
            return choicesAreDifferent ? newChoices : oldChoices;
        });

        console.log("Updated radio choices array:", radioChoicesArray);
        const processedChoices = preprocessChoices(radioChoicesArray);
        console.log("Updated radio choices array 2:", processedChoices, currentKIT);

        // And here we cler the data !!!!!
        console.log(data)

        // filter the data based on currentKIT and processed choices
        let filteredDataMine;
        if (whatWeFetch !== "ALL") {
            filteredDataMine = data.filter(kit => {
                // Split the kit_name by '_'
                let insulationThickness, pvArea, windowFrame, windowGlass;
                let thermalInsulation, pvAreaHybrid, tiles, windowGlazing, windowFrameHybrid;
                let wallUvalue, pvAreaCon;

                // Extract segments based on the kit type
                if (kit.kit === "SmartWall") {
                    const segmentsSmart = kit.kit_name.split("_");
                    insulationThickness = segmentsSmart[1]; // Second position
                    pvArea = segmentsSmart[segmentsSmart.length - 1]; // Last position
                    windowFrame = segmentsSmart[2]; // Third
                    windowGlass = segmentsSmart[3]; // Fourth
                } else if (kit.kit === "HybridWall") {
                    const segmentsHy = kit.kit_name.split("_");
                    pvAreaHybrid = segmentsHy[1]; // Second position
                    thermalInsulation = segmentsHy[2]; // Third
                    windowFrameHybrid = segmentsHy[3]; // Fourth
                    windowGlazing = segmentsHy[4]; // Fifth
                    tiles = segmentsHy[segmentsHy.length - 1]; // Last position
                } else if (kit.kit === "ConExWall") {
                    const segmentsCon = kit.kit_name.split("_");
                    wallUvalue = segmentsCon[1]; // Second position
                    pvAreaCon = segmentsCon[segmentsCon.length - 1]; // Last position
                }

                // checking IF the kit should be kept based on current choices
                const shouldKeep = currentChoices.every(({ label }) => {
                    // Logic for SmartWall
                    if (kit.kit === "SmartWall") {
                        if (label === "Insulation Thickness") {
                            return processedChoices.includes(insulationThickness);
                        }
                        if (label === "PV Area (S)") {
                            return processedChoices.includes(pvArea);
                        }
                        if (label === "Window Frame (S)") {
                            return processedChoices.includes(windowFrame);
                        }
                        if (label === "Window Glass") {
                            return processedChoices.includes(windowGlass);
                        }
                    }

                    // Logic for HybridWall
                    if (kit.kit === "HybridWall") {
                        if (label === "Window Frame (H)") {
                            return processedChoices.includes(windowFrameHybrid);
                        }
                        if (label === "Window Glazing") {
                            return processedChoices.includes(windowGlazing);
                        }
                        if (label === "Tiles") {
                            return processedChoices.includes(tiles);
                        }
                        if (label === "Thermal Insulation") {
                            return processedChoices.includes(thermalInsulation);
                        }
                        if (label === "PV Area (H)") {
                            return processedChoices.includes(pvAreaHybrid);
                        }
                    }

                    // Logic for ConExWall
                    if (kit.kit === "ConExWall") {
                        if (label === "PV Area (C)") {
                            return processedChoices.includes(pvAreaCon);
                        }
                        if (label === "Wall U Value") {
                            return processedChoices.includes(wallUvalue);
                        }
                    }

                    return true; // in case label doesn't match, keep the kit
                });

                // returning shouldKeep for the current kit and allow all others
                return shouldKeep || kit.kit !== currentKIT; // keeping kits that do not match currentKIT
            });
            setFilteredData(filteredDataMine);
        }
        if (whatWeFetch === "ALL") {
            // Filter for the current kit and retain previous filters for other kits
            const newFilteredData = data.filter(kit => {
                // Split the kit_name by '_'
                let insulationThickness, pvArea, windowFrame, windowGlass;
                let thermalInsulation, pvAreaHybrid, tiles, windowGlazing, windowFrameHybrid;
                let wallUvalue, pvAreaCon;

                // Extract segments based on the kit type
                const segments = kit.kit_name.split("_");

                if (kit.kit === "SmartWall") {
                    insulationThickness = segments[1];
                    pvArea = segments[segments.length - 1];
                    windowFrame = segments[2];
                    windowGlass = segments[3];

                } if (kit.kit === "HybridWall") {
                    pvAreaHybrid = segments[1];
                    thermalInsulation = segments[2];
                    windowFrameHybrid = segments[3];
                    windowGlazing = segments[4];
                    tiles = segments[segments.length - 1];
                } if (kit.kit === "ConExWall") {
                    wallUvalue = segments[1];
                    pvAreaCon = segments[segments.length - 1];
                    console.log("PV AA:", pvAreaCon, segments)

                }
                
                // Check if the kit should be kept based on current choices
                const shouldKeep = currentChoices.every(({ label }) => {
                    if (kit.kit === "SmartWall") {
                        if (label === "Insulation Thickness") {
                            return processedChoices.includes(insulationThickness);
                        }
                        if (label === "PV Area (S)") {
                            // const pvAreaSIs30 = currentChoices.some(({ label }) => label === "PV Area (S)" && processedChoices.includes('30')); // Check if PV Area (S) is 30
                            // const pvAreaCIs30 = currentChoices.some(({ label }) => label === "PV Area (C)" && processedChoices.includes('30')); // Check if PV Area (S) is 30
                            // const pvAreaSIs0 = currentChoices.some(({ label }) => label === "PV Area (S)" && processedChoices.includes('0')); // Check if PV Area (S) is 30
                            // const pvAreaSIs10 = currentChoices.some(({ label }) => label === "PV Area (S)" && processedChoices.includes('10')); // Check if PV Area (S) is 30
                            // const pvAreaSIs20 = currentChoices.some(({ label }) => label === "PV Area (S)" && processedChoices.includes('20')); // Check if PV Area (S) is 30
                            // const pvAreaSIs40 = currentChoices.some(({ label }) => label === "PV Area (S)" && processedChoices.includes('40')); // Check if PV Area (S) is 30

                            // if (pvAreaCIs30 && pvAreaSIs30)
                            // {
                            //     return processedChoices.includes(pvArea);
                            // }
                            // if (pvAreaCIs30 && !pvAreaSIs30)
                            // {
                            //     if (pvAreaSIs0)
                            //     {
                            //         console.log("MEX",pvAreaSIs0)
                            //         return processedChoices.includes("0");
                            //     }
                                    
                            //     if (pvAreaSIs10)
                            //     {
                            //         console.log("MEX",pvAreaSIs10)
                            //         return processedChoices.includes("10");
                            //     }
                                    
                            //     if (pvAreaSIs20)
                            //     {
                            //         console.log("MEX",pvAreaSIs20)
                            //         return processedChoices.includes("20");
                            //     }
                            //     if (pvAreaSIs40)
                            //     {
                            //         console.log("MEX",pvAreaSIs40)
                            //         return processedChoices.includes("40");
                            //     }
                            // }
                            
                            return processedChoices.includes(pvArea);
                        }
                        if (label === "Window Frame (S)") {
                            return processedChoices.includes(windowFrame);
                        }
                        if (label === "Window Glass") {
                            return processedChoices.includes(windowGlass);
                        }
                    } if (kit.kit === "HybridWall") {
                        if (label === "Window Frame (H)") {
                            return processedChoices.includes(windowFrameHybrid);
                        }
                        if (label === "Window Glazing") {
                            return processedChoices.includes(windowGlazing);
                        }
                        if (label === "Tiles") {
                            return processedChoices.includes(tiles);
                        }
                        if (label === "Thermal Insulation") {
                            return processedChoices.includes(thermalInsulation);
                        }
                        if (label === "PV Area (H)") {
                            return processedChoices.includes(pvAreaHybrid);
                        }
                    } if (kit.kit === "ConExWall") {
                        if (label === "PV Area (C)") { 
                            // const pvAreaSIs30 = currentChoices.some(({ label }) => label === "PV Area (S)" && processedChoices.includes('30')); // Check if PV Area (S) is 30
                            // const pvAreaCIs30 = currentChoices.some(({ label }) => label === "PV Area (C)" && processedChoices.includes('30')); // Check if PV Area (S) is 30
                            // const pvAreaCIs24 = currentChoices.some(({ label }) => label === "PV Area (C)" && processedChoices.includes('24')); // Check if PV Area (S) is 30
                            // const pvAreaCIs36 = currentChoices.some(({ label }) => label === "PV Area (C)" && processedChoices.includes('36')); // Check if PV Area (S) is 30
                            // const pvAreaCIs42 = currentChoices.some(({ label }) => label === "PV Area (C)" && processedChoices.includes('42')); // Check if PV Area (S) is 30
                            // const pvAreaCIs48 = currentChoices.some(({ label }) => label === "PV Area (C)" && processedChoices.includes('48')); // Check if PV Area (S) is 30

                            // if (pvAreaCIs30 && pvAreaSIs30)
                            // {
                            //     return processedChoices.includes(pvAreaCon);
                            // }
                            // if (!pvAreaCIs30 && pvAreaSIs30)
                            // {
                            //     if (pvAreaCIs24)
                            //         return processedChoices.includes("24");
                            //     if (pvAreaCIs36)
                            //         return processedChoices.includes("36");
                            //     if (pvAreaCIs42)
                            //         return processedChoices.includes("42");
                            //     if (pvAreaCIs48)
                            //         return processedChoices.includes("48");
                            // }
                            // if (pvAreaCIs30 && !pvAreaSIs30)
                            // {
                            //     return processedChoices.includes(pvAreaCon);

                            // }
                            // if (!pvAreaCIs30 && !pvAreaSIs30)
                            // {
                            //     return processedChoices.includes(pvAreaCon);

                            // }
                            return processedChoices.includes(pvAreaCon);     
                        }
                        if (label === "Wall U Value") {
                            return processedChoices.includes(wallUvalue);
                        }
                    }
                    return true; // IF label doesnt match, keep the kit
                });
                
                
                console.log("PV Area Processing Kit:", kit.kit);
                console.log("PV Area (S):", pvArea);
                console.log("PV Area (C):", pvAreaCon);
                console.log(" PV Area Processed Choices:", processedChoices);
                //  shouldKeep for the current kit only
                return shouldKeep;
            });

            //### TRIAL 1
            // setFilteredResults(prevFilteredResults => {
            //     const updatedFilteredResults = {
            //         ...prevFilteredResults,
            //         [currentKIT]: newFilteredData.length > 0 ? newFilteredData : prevFilteredResults[currentKIT] || []
            //     };

            //     // Ensure that filteredData is updated right away for the current kit
            //     const combinedFilteredData = Object.entries(updatedFilteredResults)
            //         .flatMap(([kitType, results]) => {
            //             // This ensures you always display results for the current kit
            //             if (kitType === currentKIT) {
            //                 return newFilteredData.length > 0 ? newFilteredData : results; // Use current filtered data
            //             }
            //             return results; // Keep other kits' results unchanged
            //         })
            //         .filter((kit, index, self) => 
            //             index === self.findIndex(k => k.kit_name === kit.kit_name) // Remove duplicates
            //         );

            //     // Update both filteredResults and filteredData immediately

            //     setFilteredData(combinedFilteredData); // This should force UI to update
            //     setCurrentKIT("SmartWall")
            //     return updatedFilteredResults; // Ensure filtered results state is maintained
            // });


            //TRIAL 2
            // setFilteredResults(prevFilteredResults => {
            //     // Create a new filtered result for the current kit based on the new filter
            //     const updatedFilteredResults = {
            //         ...prevFilteredResults,
            //         [currentKIT]: newFilteredData.length > 0 ? newFilteredData : prevFilteredResults[currentKIT] || []
            //     };

            //     // Create a combined filtered data set based on the new filtered results
            //     const combinedFilteredData = Object.entries(updatedFilteredResults)
            //         .flatMap(([kitType, results]) => {
            //             // For the current kit, use the newly filtered data (even if empty)
            //             if (kitType === currentKIT) {
            //                 return newFilteredData; // Use only the latest filtered data for current kit
            //             }
            //             return results; // Preserve other kits' filtered data
            //         })
            //         .filter((kit, index, self) =>
            //             index === self.findIndex(k => k.kit_name === kit.kit_name) // Remove duplicates
            //         );

            //     // Ensure both filteredResults and filteredData are updated in real-time
            //     setFilteredData(combinedFilteredData);  // Update filtered data to reflect current filters

            //     setCurrentKIT("SmartWall")

            //     return updatedFilteredResults;  // Maintain the updated filtered results state
            // });

            //TRIAL 3 
            setFilteredResults(prevFilteredResults => {
                // creating a new filtered result for the current kit based on the new filter
                const updatedFilteredResults = {
                    ...prevFilteredResults,
                    [currentKIT]: newFilteredData.length > 0 ? newFilteredData : prevFilteredResults[currentKIT] || []
                };

                // create=ing a combined filtered data set based on the new filtered results
                let foundFirstGroup = new Set(); // tracking which kit types' first group has been found
                let currentGroupKit = null; // tracking the current kit type for consecutive rows
                let firstGroupFlag = false; // tracking whether we are still in the first group of a kit

                const combinedFilteredData = Object.entries(updatedFilteredResults)
                    .flatMap(([kitType, results]) => {
                        if (kitType === currentKIT) {
                            return newFilteredData; // Use the latest filtered data for the current kit
                        }
                        return results; // Preserve other kits' filtered data
                    })
                    .filter(kit => {
                        // incase kit type changes (i.e., we move to a new group), reset firstGroupFlag
                        if (kit.kit !== currentGroupKit) {
                            currentGroupKit = kit.kit; // updating current kit type
                            firstGroupFlag = !foundFirstGroup.has(kit.kit); 

                            if (firstGroupFlag) {
                                foundFirstGroup.add(kit.kit); // mark kits first group 
                                return true; // keep rows as it belongs to first group
                            }

                            return false; // skipping since it's not the first group
                        }

                        // in case  we are still in the first group of the current kit type, keep it
                        return firstGroupFlag; // True means keep all rows in this group, false skips them
                    });

                // updating both filteredResults and filteredData 
                setFilteredData(combinedFilteredData); // updated filtered data to reflect current filters

                setCurrentKIT("SmartWall"); // rerender logic

                return updatedFilteredResults; //  updated filtered results state
            });




            // # #@################## This worked good enough ########################

            // const updatedFilteredResults = {
            //     ...filteredResults,
            //     [currentKIT]: newFilteredData
            //      // Update current kit's results only
            // };

            // // Combine the filtered results for the current kit with previous filters applied
            // const combinedFilteredData = Object.entries(updatedFilteredResults)
            //     .flatMap(([kitType, results]) => {
            //         // If the kitType is the current kit, apply new filters cumulatively
            //         if (kitType === currentKIT) {
            //             // Start with previously filtered data for this kit
            //             const previousFilteredData = filteredResults[currentKIT] || data.filter(kit => kit.kit === currentKIT);

            //             // Apply the new filter to the already filtered data for the current kit
            //             return newFilteredData.length > 0 ? newFilteredData : previousFilteredData;
            //         }
            //         return results; // Keep results for other kits as they are
            //     })
            //     .filter((kit, index, self) => 
            //         index === self.findIndex(k => k.kit_name === kit.kit_name) // Remove duplicates
            //     );

            // // Update the state with the filtered results
            // setFilteredResults(updatedFilteredResults); 
            // setFilteredData(combinedFilteredData);

            // console.log("Combined Filtered Data:", combinedFilteredData);


            // console.log("Combined Filtered Data:", combinedFilteredData);
            // console.log("Filtered Data:", updatedFilteredResults, filteredResults);
            // console.log("Original Data:", data);

            //#############THIS WORKED GOOD ENOUGH?????????????????????
        }



        // Log filtered data and set state
        //console.log("Filtered Data:", filteredDataMine, filteredData,processedChoices);
        //setFilteredData(filteredDataMine); // Update the filteredData state


    }, [cardList, radioChoicesArray, currentKIT, weSubmit]);
    //############ The custom carousel ####################//



    //#################### TRIAL ####################
    function clearAllRight() {
        setWhichKPI(prev => {
            const newKPI = Math.min(2, prev + 1);
            // Update currentKIT based on newKPI
            setCurrentKIT(getKITName(newKPI));
            setCurrentKIT2(getKITName(newKPI))// helper function to get the name
            return newKPI; // Return the new value for whichKPI
        });
        // clearAll();
        // setCardList([]);
    }

    function clearAllLeft() {
        setWhichKPI(prev => {
            const newKPI = Math.max(0, prev - 1);
            // Update currentKIT based on newKPI
            setCurrentKIT(getKITName(newKPI));
            setCurrentKIT2(getKITName(newKPI))// helper function to get the name
            return newKPI; // Return the new value for whichKPI
        });
        // clearAll();
        // setCardList([]);
    }

    // Helper function to get currentKIT name based on whichKPI
    const getKITName = (kpi) => {
        switch (kpi) {
            case 0:
                return "SmartWall";
            case 1:
                return "HybridWall";
            case 2:
                return "ConExWall";
            default:
                return "";
        }
    };



    return (
        <div className="home_main_div">
            <div className="sidebar_unfetched">
                <div className="logo"><li><img src={logo_letter} alt={"plural_logo"} /><img src={logo_house} alt={"plural_logo"} /></li></div>
                <h5>MAIN</h5>
                <li onClick={() => navigate("/modest")} style={{ color: "white", backgroundColor: "rgba(4,188,183,255)" }}><BuildIcon className="mui_icon" style={{ color: "white" }} />MODEST</li>
                <li onClick={() => navigate("/home")}><EqualizerIcon className="mui_icon" />Site Monitoring</li>
                <h5>USER</h5>
                <li><AccountBoxIcon className="mui_icon" /> Profile</li>
                {!!keycloak.authenticated && (<li onClick={() => { navigate("/"); keycloak.logout() }}><LogoutIcon className="mui_icon" />Logout ({keycloak.tokenParsed.preferred_username})</li>)}
            </div>
            <div className="inner_home_div_modest">
                <div className="button_div">
                    <div>
                        <label>Search by: </label>
                        <input type="radio" value="ALL" name="columns" onChange={(e) => radioButtonChecked(e)} /> All
                        <input type="radio" value="SITE" name="columns" onChange={(e) => radioButtonChecked(e)} /> Site
                        <input type="radio" value="KIT" name="columns" onChange={(e) => radioButtonChecked(e)} /> Kit
                        {radioButton === "KIT" ? <><label>Kit: </label>
                            <select onChange={selectKit}>
                                {kits.map((kit) => (
                                    <option key={kit}>{kit}</option>))}
                            </select></> : <></>}
                        {radioButton === "SITE" ? <><label>Site: </label>
                            <select onChange={selectSite}>
                                {sites.map((site) => (
                                    <option key={site}>{site}</option>))}
                            </select></> : <></>}
                    </div>

                    {radioButton !== "None" ? <button className="export_button" onClick={fetchGeneral} style={{ height: "35px" }}>Submit</button> : <div></div>}
                </div>
                {showModels ? <div className="beautifyTheDiv">
                    {/* The below is used in order to Fetch Data from The KAFKA topic - IT works with Object (PnUObj) and it Used as Columns the "mycolumns" array above */}

                    {/***********************THIS IS CORRECT UP TO A POINT TO BE IMPLEMENTED ON VERSION 2, 3 ****************/}
                    <div className="widget_PnU">
                        <div className="smartWallDiv">
                            <div style={{ display: "flex", justifyContent: "space-around", paddingLeft: "10px" }}>
                                {hideArr ? <></> : <div onClick={clearAllLeft}
                                    style={{ cursor: "pointer", zIndex: 1, height: "20px", color: hover ? "rgb(56, 191, 191)" : "gray", marginTop: "-30px", transform: hover ? "scale(1.2)" : "scale(1)" }}
                                    onMouseEnter={() => setHover(true)}
                                    onMouseLeave={() => setHover(false)}
                                >
                                    <ArrowCircleLeftIcon />
                                </div>}
                                <div style={{ width: "200px", height: "200px" }}>
                                    {whichKPI === 0 ?
                                        <div style={{ marginLeft: "30%", marginTop: "-25px" }}> Smart Wall</div> :
                                        whichKPI === 1 ? <div style={{ marginLeft: "30%", marginTop: "-25px" }}> Hybrid Wall</div> :
                                            <div style={{ marginLeft: "30%", marginTop: "-25px" }}> ConEx Wall</div>}
                                    {whichKPI === 0 ? (
                                        <SmartWall
                                            showMeTheClass={showMeTheClass}
                                            isActive={isActive}
                                            isActive2={isActive2}
                                            isActive3={isActive3}
                                            isActive4={isActive4}
                                            isActive5={isActive5}
                                            isActive6={isActive6}
                                        />
                                    ) : whichKPI === 1 ? (
                                        <HybridWall
                                            dosome={showMeTheClass}
                                            isActiveWin1={isActiveWin1}
                                            isActiveWin2={isActiveWin2}
                                            isActiveWin3={isActiveWin3}
                                            isActiveWin4={isActiveWin4}
                                            isActiveWin5={isActiveWin5}
                                            isActiveWin6={isActiveWin6}
                                            isActiveWin7={isActiveWin7}
                                            isActiveWin8={isActiveWin8}
                                            isActiveWin9={isActiveWin9}
                                            isActiveWin10={isActiveWin10} />
                                    ) : (
                                        <ConExWall
                                            showMeTheClass={showMeTheClass}
                                            isActiveCon={isActiveCon}
                                            isActiveCon2={isActiveCon2}
                                            isActiveCon3={isActiveCon3}
                                            isActiveCon4={isActiveCon4}
                                            isActiveCon5={isActiveCon5}
                                            isActiveCon6={isActiveCon6}
                                        />
                                    )}
                                </div>
                                {hideArr ? <></> : <div onClick={clearAllRight}
                                    style={{ cursor: "pointer", zIndex: 1, height: "20px", color: hoverR ? "rgb(56, 191, 191)" : "gray", marginTop: "-30px", transform: hoverR ? "scale(1.2)" : "scale(1)" }}
                                    onMouseEnter={() => setHoverR(true)}
                                    onMouseLeave={() => setHoverR(false)}
                                >
                                    <ArrowCircleRightIcon />
                                </div>}
                            </div>
                        </div>
                        <div className="radioGroup" style={{ marginLeft: "70px" }}>
                            <FormControl component="fieldset" sx={{ color: 'rgb(56, 191, 191)' }}>
                                <FormLabel component="legend" sx={{ color: 'rgb(56, 191, 191)' }}>{radioLabel}</FormLabel>
                                <RadioGroup aria-label="gender" name="gender1" sx={{ color: 'rgb(56, 191, 191)' }} onChange={handleRadioChange}>
                                    {numberOfOptions > 0 ? <><FormControlLabel value={radioOption1} control={<Radio />} label={radioOption1} />
                                        <FormControlLabel value={radioOption2} control={<Radio />} label={radioOption2} /></> : <></>}
                                    {numberOfOptions === 3 ? <FormControlLabel value={radioOption3} control={<Radio />} label={radioOption3} /> : <></>}
                                    {numberOfOptions === 4 ? <><FormControlLabel value={radioOption3} control={<Radio />} label={radioOption3} /><FormControlLabel value={radioOption4} control={<Radio />} label={radioOption4} /></> : <></>}
                                    {numberOfOptions === 5 ? <><FormControlLabel value={radioOption3} control={<Radio />} label={radioOption3} /><FormControlLabel value={radioOption4} control={<Radio />} label={radioOption4} />
                                        <FormControlLabel value={radioOption5} control={<Radio />} label={radioOption5} /></> : <></>}
                                </RadioGroup>
                            </FormControl>
                            <div className="button_sub">
                                {hide ? <button className="subBut" onClick={submit_label_choise} style={{ zIndex: 1 }}>Submit</button> : <></>}
                            </div>
                        </div>
                    </div>
                    <div className="widget_KPI" >
                        {cardList.map((card, index) => (
                            <div style={{ width: "175%" }} key={index} >
                                {card}
                            </div>))}
                    </div>
                </div> : <></>}
                {/* PRIMEREACT PART */}
                {tableToRender !== "ALL" ?
                    <DataTable scrollHeight="600px" selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => alertingOnClick(e)} headerColumnGroup={headerGroup} stripedRows size="small" className="data_table" header={header1} emptyMessage="No data match your query...." first={first1} rows={rows1} onPage={onCustomPage1} paginator paginatorTemplate={template1} removableSort showGridlines value={filteredData} sortMode="multiple" responsiveLayout="scroll" style={{ minHeight: '600px', margin: '5px' }}>
                        {columnComponents}
                    </DataTable> :
                    <DataTable scrollHeight="600px" selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => alertingOnClick(e)} headerColumnGroup={headerGroupAll} stripedRows size="small" className="data_table" header={header1} emptyMessage="No data match your query...." first={first1} rows={rows1} onPage={onCustomPage1} paginator paginatorTemplate={template1} removableSort showGridlines value={filteredData} sortMode="multiple" responsiveLayout="scroll" style={{ minHeight: '600px', margin: '5px' }}>
                        {columnComponents}
                    </DataTable>}
            </div>
        </div >

    )
}


export default Modest;
