import "../scss/InfoPnUCard.scss";
import ClearIcon from '@mui/icons-material/Clear';
function InfoPnUCard({infocard_text, currentKit,removeCard}) {
    return (
        <div className="infocard" key={infocard_text}>
            <div className="infocard_text">
            <span style={{fontWeight:"600", marginRight:"5px", color:currentKit==="SmartWall"?"blue":currentKit==="HybridWall"?"red":"grey"}}>{currentKit}</span> {infocard_text}
            </div>
            <div className="infocard_icon" onClick={removeCard}>
                <ClearIcon />
            </div>
        </div>
    );
}

export default InfoPnUCard;